// Icons
//import admin from "./../../assets/images/Admin-portal-svg/admin-icon.svg";
import { HiOutlineUserAdd, HiOutlineFolderAdd } from "react-icons/hi";
// HiOutlineUserAdd

//import clientSetup from "./../../assets/images/Admin-portal-svg/client-setup-icon.svg";
import { RiUserSettingsLine } from "react-icons/ri";

//import dashboard from "./../../assets/images/Admin-portal-svg/dashboard-icon.svg";
import { MdSpaceDashboard } from "react-icons/md";

//import insurance from "./../../assets/images/Admin-portal-svg/insurance-icon.svg";
import { HiOutlineDocumentCheck } from "react-icons/hi2";

//import coding from "./../../assets/images/Admin-portal-svg/coding-icon.svg";
import { VscFileCode } from "react-icons/vsc";

//import institutional from "./../../assets/images/Admin-portal-svg/institutional-icon.svg";
// import {TbBuildingBank} from "react-icons/tb";

//import edi from "./../../assets/images/Admin-portal-svg/edi-code-icon.svg";
import { TbTransform } from "react-icons/tb";

//import settings from "./../../assets/images/Admin-portal-svg/settings-icon.svg";
import { IoSettingsOutline } from "react-icons/io5";

let SideMenu = [
  {
    key: 1,
    value: "Dashboard",
    label: "Dashboard",
    icon: "icon-home",
    // image: dashboard,
    image: MdSpaceDashboard,
    active: true,
    rights: true,
    path: "/dashboard",
    isSubMenuOpen: false,
    SubMenu: [],
  },
  {
    key: 2,
    value: "ClientSetup",
    label: "Client Setup",
    icon: "icon-diamond",
    // image: clientSetup,
    image: RiUserSettingsLine,
    active: false,
    rights: true,
    isSubMenuOpen: false,
    path: "/database",
    SubMenu: [
      {
        key: 21,
        value: "Database",
        label: "Database",
        // icon: "",
        // image: database,
        active: false,
        rights: true,
        path: "/database",
      },
      {
        key: 22,
        value: "Client",
        label: "Client",
        // icon: "",
        // image: client,
        active: false,
        rights: true,
        path: "/client",
      },
      {
        key: 23,
        value: "Practice",
        label: "Practice",
        icon: "",
        active: false,
        rights: true,
        path: "/practice",
      },
    ],
  },
  {
    key: 3,
    value: "Practice Setup",
    label: "Practice Setup",
    icon: "icon-admin",
    // image: admin,
    image: HiOutlineUserAdd,
    active: false,
    rights: true,
    isSubMenuOpen: false,
    path: "/location",
    SubMenu: [
      // {
      //   key: 34,
      //   value: 'Practice Provider',
      //   label: 'Practice Provider',
      //   icon: '',
      //   active: false,
      //   rights: true,
      //   path: '/practiceProvider',
      // },
      // {
      //   key: 34,
      //   value: "Practice Provider",
      //   label: "Practice Provider",
      //   icon: "",
      //   active: false,
      //   rights: true,
      //   path: "/practiceProvider",
      // },
      {
        key: 31,
        value: "Location",
        label: "Location",
        icon: "",
        active: false,
        rights: true,
        path: "/location",
      },
      {
        key: 32,
        value: "Provider",
        label: "Provider",
        icon: "",
        active: false,
        rights: true,
        path: "/provider",
      },
      {
        key: 33,
        value: "Referring Provider",
        label: "Referring Provider",
        icon: "",
        active: false,
        rights: true,
        path: "/referringProvider",
      },
    ],
  },
  {
    key: 4,
    value: "User Setup",
    label: "User Setup",
    icon: "icon-admin",
    // image: admin,
    image: HiOutlineUserAdd,
    active: false,
    rights: true,
    isSubMenuOpen: false,
    path: "/user",
    SubMenu: [
      {
        key: 41,
        value: "User",
        label: "User",
        // icon: "",
        // image: user,
        active: false,
        rights: true,
        path: "/user",
      },
      {
        key: 42,
        value: "Team",
        label: "Team",
        // icon: "",
        // image: team,
        active: false,
        rights: true,
        path: "/team",
      },
    ],
  },
  {
    key: 5,
    value: "Insurance",
    label: "Insurance",
    icon: "icon-insurance",
    // image: insurance,
    image: HiOutlineDocumentCheck,
    active: false,
    rights: true,
    isSubMenuOpen: false,
    path: "/insuranceGroup",
    SubMenu: [
      {
        key: 51,
        value: "Insurance Group",
        label: "Insurance Group",
        icon: "",
        active: false,
        rights: true,
        path: "/insuranceGroup",
      },
      // {
      //   key: 52,
      //   value: "Insurance Payer ",
      //   label: "Insurance Payer ",
      //   icon: "",
      //   active: false,
      //   rights: true,
      //   path: "/InsurancePayer",
      // },

      {
        key: 53,
        value: "Plan Type",
        label: "Insurance Plan Type",
        icon: "",
        active: false,
        rights: true,
        path: "/planType",
      },
      {
        key: 54,
        value: "InsurancePlan",
        label: "Insurance Plan",
        icon: "",
        active: false,
        rights: true,
        path: "/insuranceplan",
      },
      {
        key: 55,
        value: "InsurancePlanAddress",
        label: "Insurance Plan Address",
        icon: "",
        active: false,
        rights: true,
        path: "/insurancePlanAddress",
      },

      {
        key: 56,
        value: "Insurance Plan Edi Setup ",
        label: "Insurance Plan Edi Setup ",
        icon: "",
        active: false,
        rights: true,
        path: "/insurancePlanEdiSetup",
      },
      {
        key: 58,
        value: "Provider Enrollment",
        label: "Provider Enrollment",
        icon: "",
        active: false,
        rights: true,
        path: "/providerEnrollment",
      },

      {
        key: 57,
        value: "insuranceMapping",
        label: "Insurance Mapping",
        icon: "",
        active: false,
        rights: true,
        path: "/insuranceMapping",
      },
    ],
  },
  {
    key: 6,
    value: "Coding",
    label: "Coding",
    icon: "icon-coding",
    // image: coding,
    image: VscFileCode,
    active: false,
    rights: true,
    isSubMenuOpen: false,
    path: "/icd",
    SubMenu: [
      {
        key: 61,
        value: "Icd",
        label: "ICD",
        icon: "",
        active: false,
        rights: true,
        path: "/icd",
      },

      {
        key: 62,
        value: "Cpt",
        label: "CPT",
        icon: "",
        active: false,
        rights: true,
        path: "/cpt",
      },

      {
        key: 64,
        value: "Modifiers",
        label: "Modifiers",
        icon: "",
        active: false,
        rights: true,
        path: "/modifiers",
      },
      {
        key: 65,
        value: "Pos",
        label: "POS",
        icon: "",
        active: false,
        rights: true,
        path: "/pos",
      },
    ],
  },
  // {
  //   key: 7,
  //   value: "Institutional",
  //   label: "Institutional",
  //   icon: "icon-institutional",
  //   // image: institutional,
  //   image: TbBuildingBank,
  //   active: false,
  //   rights: true,
  //   isSubMenuOpen: false,
  //   path: "/revenueCode",
  //   SubMenu: [
  //     {
  //       key: 71,
  //       value: "RevenueCode",
  //       label: "Revenue Code",
  //       icon: "",
  //       active: false,
  //       rights: true,
  //       path: "/revenueCode",
  //     },
  //     {
  //       key: 72,
  //       value: "ValueCode",
  //       label: "Value Code",
  //       icon: "",
  //       active: false,
  //       rights: true,
  //       path: "/valueCode",
  //     },
  //     {
  //       key: 73,
  //       value: "ConditionCode",
  //       label: "Condition Code",
  //       icon: "",
  //       active: false,
  //       rights: true,
  //       path: "/conditionCode",
  //     },
  //     {
  //       key: 74,
  //       value: "OccuranceCode",
  //       label: "Occurance Code",
  //       icon: "",
  //       active: false,
  //       rights: true,
  //       path: "/occuranceCode",
  //     },
  //     {
  //       key: 75,
  //       value: "OccuranceSpanCode ",
  //       label: "Occurance Span Code ",
  //       icon: "",
  //       active: false,
  //       rights: true,
  //       path: "/occuranceSpanCode",
  //     },
  //   ],
  // },
  {
    key: 8,
    value: "EDICodes",
    label: "EDI Codes",
    icon: "icon-edi-code",
    // image: edi,
    image: TbTransform,
    active: false,
    rights: true,
    isSubMenuOpen: false,
    path: "/adjustmentAction",
    SubMenu: [
      // {
      //   key: 81,
      //   value: "AdjustmentCode",
      //   label: "Adjustment Code",
      //   icon: "",
      //   active: false,
      //   rights: true,
      //   path: "/adjustmentCode",
      // },
      // {
      //   key: 81,
      //   value: "RemarkCode",
      //   label: "Remark Code",
      //   icon: "",
      //   active: false,
      //   rights: true,
      //   path: "/remarkCode",
      // },
      {
        key: 81,
        value: "Adjustment Actions",
        label: "Payment Adjustment Actions",
        icon: "",
        active: false,
        rights: true,
        path: "/adjustmentAction",
      },
    ],
  },
  // {
  //   key: 12,
  //   value: "RolesAndRights",
  //   label: "Roles & Rights",
  //   icon: "icon-settings",
  //   // image: settings,
  //   image: IoSettingsOutline,
  //   active: false,
  //   rights: true,
  //   isSubMenuOpen: false,
  //   path: "/RolesAndRights",
  // },
  {
    key: 9,
    value: "Script Store",
    label: "Script Store",
    icon: "icon-receiver",
    // image: edi,
    image: TbTransform,
    active: false,
    rights: true,
    isSubMenuOpen: false,
    path: "/DBScript",
    SubMenu: [
      {
        key: 91,
        value: "DBScript",
        label: "DB Script",
        icon: "",
        active: false,
        rights: true,
        path: "/DBScript",
      },
    ],
  },

  {
    key: 10,
    value: "Submission Setup",
    label: "Submission Setup",
    icon: "icon-receiver",
    // image: edi,
    image: HiOutlineFolderAdd,
    active: false,
    rights: true,
    isSubMenuOpen: false,
    path: "/Receiver",
    SubMenu: [
      {
        key: 101,
        value: "Receiver",
        label: "Receiver",
        icon: "",
        active: false,
        rights: true,
        path: "/Receiver",
      },
      {
        key: 101,
        value: "Submitter",
        label: "Submitter",
        icon: "",
        active: false,
        rights: true,
        path: "/Submitter",
      },
    ],
  },

  // {
  //   key: 9,
  //   value: "DataMigration",
  //   label: "Data Migration",
  //   icon: "datamigration",
  //   image: datamigration,
  //   active: false,
  //   rights: true,
  //   isSubMenuOpen: false,
  //   path: "/externalFiles",
  //   SubMenu: [
  //     {
  //       key: 91,
  //       value: "ExternalFiles",
  //       label: "External Files",
  //       icon: "",
  //       active: false,
  //       rights: true,
  //       path: "/externalFiles",
  //     },
  //     {
  //       key: 92,
  //       value: "PatientSheet ",
  //       label: "Patient Sheet ",
  //       icon: "",
  //       active: false,
  //       rights: true,
  //       path: "/patientSheet ",
  //     },
  //     {
  //       key: 93,
  //       value: "ChargesSheet  ",
  //       label: "Charges Sheet  ",
  //       icon: "",
  //       active: false,
  //       rights: true,
  //       path: "/chargesSheet  ",
  //     },
  //     {
  //       key: 94,
  //       value: "InsuranceMapping  ",
  //       label: "Insurance Mapping ",
  //       icon: "",
  //       active: false,
  //       rights: true,
  //       path: "/insuranceMapping  ",
  //     },
  //     {
  //       key: 95,
  //       value: "SchedulerJob  ",
  //       label: "Scheduler Job",
  //       icon: "",
  //       active: false,
  //       rights: true,
  //       path: "/schedulerJob  ",
  //     },
  //     {
  //       key: 96,
  //       value: "MismatchedVisits  ",
  //       label: "Mismatched Visits",
  //       icon: "",
  //       active: false,
  //       rights: true,
  //       path: "/mismatchedVisits  ",
  //     },
  //   ],
  // },
  {
    key: 12,
    value: "ExternalSettings",
    label: "External Setting",
    icon: "icon-externalSetting",
    // image: dashboard,
    image: IoSettingsOutline,
    active: false,
    rights: true,
    path: "/externalSetting",
    isSubMenuOpen: false,
    SubMenu: [],
  },
  {
    key: 11,
    value: "Setting",
    label: "Setting",
    icon: "icon-settings",
    // image: settings,
    image: IoSettingsOutline,
    active: false,
    rights: true,
    isSubMenuOpen: false,
    path: "/taxonomycode",
    SubMenu: [
      {
        key: 111,
        value: "TaxonomyCode",
        label: "Taxonomy Code",
        icon: "",
        active: false,
        rights: true,
        path: "/taxonomycode",
      },
      {
        key: 112,
        value: "ZipCode",
        label: "Zip Code",
        icon: "",
        active: false,
        rights: true,
        path: "/zipcode",
      },
      // {
      //   key: 113,
      //   value: "Group",
      //   label: "Group",
      //   icon: "",
      //   active: false,
      //   rights: true,
      //   path: "/group",
      // },
      // {
      //   key: 114,
      //   value: "Action",
      //   label: "Action",
      //   icon: "",
      //   active: false,
      //   rights: true,
      //   path: "/action",
      // },
    ],
  },
];

export { SideMenu };
