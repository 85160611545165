import React, { useState } from "react";
import LogoImage from "../assets/images/logo.png";
import pharmacy from "../assets/images/pharmacy.png";
// import medifusionlogo from "../assets/images/logo-medifusion.png";
import { MyButton } from "../Common/MyButton";
import { MyInputField } from "../Common/MyInput";
import { useDispatch } from "react-redux";
// import { LockAction, LoginAction, userInfo } from "../Redux/Actions";


import encryption from "./../Utils/EncryptPassword";
import { useNavigate } from "react-router-dom";

import "./Login.css";
import axios from "axios";
import { PublicApiCall } from "../Api/PublicApiCall";
import { ErrorHandlingMessage } from "../Utils/error-handling-message";
import toaster from "../Common/toaster";
import { enumUtil } from "../Common/enum";
import { lock, login, userInfo } from "../Redux/Slices/AuthSlice";

export default function Login() {

  
  let url = process.env.REACT_APP_AuthenticationService;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let navigate = useNavigate();

  const dispatch = useDispatch();
  const [showPass, setshowPass] = useState(false);
  const onChangeInputValue = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "email") {
      setEmail(value);
    } else {
      setPassword(value);
    }
  };
  const isProccedDasboard = async (Tokenn) => {
    //return true
    let webApiUrl = url + "/Account/GetUserLoginInfoAdmin";
    let tokenStr = Tokenn;
    try {
      let res = await axios.get(webApiUrl, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      // userName:res.data.dataModel.loginUserInfo.userName,practiceName:res.data.dataModel.practiceDetails.name
      dispatch(
        userInfo({
          loginUserInfo: res.data.dataModel.loginUserInfo,
          practiceDetails: res.data.dataModel.practiceDetails,
          practiceUsers: res.data.dataModel.practiceUsers,
        })
      );

      return true;
    } catch (error) {
      let modal ={
        statusCode : error.response?.data?.statusCode,
        message : error.response?.data?.responseMessage
      }
      ErrorHandlingMessage(modal);
      setIsLoading(false);
      // return false;
    }
  };
  const onLogin = () => {
    let obj = {
      email,
      password,
    };
    let errors = [
      !email && "Please Enter Email",
      !password && "Please Enter Password",
    ].filter((v) => v !== false);
    if (errors.length > 0) {
      toaster(errors[0], enumUtil.enumtoaster.error);
      return;
    }

    setIsLoading(true);
    obj.password = encryption(password);
    PublicApiCall("/account/login", "post", obj)
      .then(async (res) => {
        let dashboardOpen = await isProccedDasboard(res.token);

        if (dashboardOpen === true) {
          setIsLoading(false);

          dispatch(
            lock({
              timeOut: res.timeOut,
              email,

              encyptPassword: obj.password,
              token: res.token,
            })
          );
          dispatch(login(res.token));
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        console.log("err");
        setIsLoading(false); 
        
        ErrorHandlingMessage(err);
        console.log(err);
      });
  };
  const enterHandler = (e) => {
    if (e.charCode === 13) {
      onLogin(e);
    }
  };
  const passToggle = () => {
    setshowPass(!showPass);
  };

  return (

    <>
      <div className="fluid-container">
        <div className="row no-gutters  login__container d-flex justify-content-center flex-column align-items-center position-relative">
          <div className="login__heading text-uppercase mb-4">
            <h1 className="font-weight-bold ">Admin Access <br />INFORMATION</h1>
          </div>
          <div className="login__form d-flex flex-column">
            <h2 className="text-center mb-2">Login</h2>
            <div className="mb-3 form-group">
              <MyInputField
                isInputLabel={true}
                inputLabelText="Email address"
                inputId="loginEmail"
                inputName="email"
                inputType="email"
                inputValue={email}
                inputOnChange={onChangeInputValue}
                inputOnKeyPress={enterHandler}
              />
            </div>



            <div className="form-group" style={{ position: "relative" }}>
              <MyInputField
                isInputLabel={true}
                inputLabelText="Password"
                inputId="loginEmail"
                inputName="password"
                inputType={showPass ? "text" : "password"}
                inputValue={password}
                inputOnChange={onChangeInputValue}
                inputOnKeyPress={enterHandler}
              />
              <i
                style={{
                  position: "absolute",
                  top: "33px",
                  right: "10px",
                  cursor: "pointer",
                }}
                onClick={passToggle}
                class={showPass ? "fa fa-eye" : "fa fa-eye-slash"}
                aria-hidden="true"
              ></i>
              {/* <i class="fa fa-eye-slash" aria-hidden="true"></i> */}
              <MyButton
                buttonId="loginBtnId"
                buttonLabel="Login"
                buttonClasses="btn btn-primary px-4 my-3 "
                buttonOnchange={onLogin}
                disabledAndLoading={isLoading}
              />
            </div>
            <div className="mt-4 pb-2">
              <a href="/">Forgot Password</a>
            </div>
          </div>
          <img src={LogoImage} className="position-absolute login__logo" alt="" />
          <img src={pharmacy} className="position-absolute pharmacy__logo" alt="" />
          <div className="position-absolute d-none d-md-block login__text" alt="" >

            <span>GET IT, CHECK IT, USE IT</span>

          </div>
          {/* <div
          style={{
            padding: "0",
          }}
          className="col-lg-6 col-sm-2"
        >
          <div className="loginleftpicture">
            <img src={LogoImage} alt="LogoImage" />
          </div>
        </div> */}

        </div>
      </div>

    </>
  );
}


// <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
// <div className="LoginPageRightDiv">
//   <form className="formloginres">
//     <img src={medifusionlogo} alt="" class="mb-4" />
//     <h3
//       style={{
//         fontWeight: "900",
//       }}
//       className=""
//     >
//       Login
//     </h3>
//     <div className="form-group mt-5">
      // <MyInputField
      //   isInputLabel={true}
      //   inputLabelText="Email address"
      //   inputId="loginEmail"
      //   inputName="email"
      //   inputType="email"
      //   inputValue={email}
      //   inputOnChange={onChangeInputValue}
      //   inputOnKeyPress={enterHandler}
      // />
//     </div>
    // <div className="form-group" style={{ position: "relative" }}>
    //   <MyInputField
    //     isInputLabel={true}
    //     inputLabelText="Password"
    //     inputId="loginEmail"
    //     inputName="password"
    //     inputType={showPass ? "text" : "password"}
    //     inputValue={password}
    //     inputOnChange={onChangeInputValue}
    //     inputOnKeyPress={enterHandler}
    //   />
    //   <i
    //     style={{
    //       position: "absolute",
    //       top: "33px",
    //       right: "10px",
    //       cursor: "pointer",
    //     }}
    //     onClick={passToggle}
    //     class={showPass ? "fa fa-eye" : "fa fa-eye-slash"}
    //     aria-hidden="true"
    //   ></i>
    //   {/* <i class="fa fa-eye-slash" aria-hidden="true"></i> */}
    // </div>
    // {/* <div className="mt-4 pb-2">
    //   <a href="/">Forgot Password</a>
    // </div> */}
    // <MyButton
    //   buttonId="loginBtnId"
    //   buttonLabel="Login"
    //   buttonClasses="btn btn-primary btn-block "
    //   buttonOnchange={onLogin}
    //   disabledAndLoading={isLoading}
    // />
//   </form>
// </div>
// </div>