import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    lock: null,
    login: null,
    userInfo: null,
}

export const AuthSlice = createSlice({
    name: 'Auth',
    initialState,
    reducers: {
        lock: (state, action) => {
            state.lock = action.payload
        },
        login: (state, action) => {
            state.login = action.payload
        },
        userInfo: (state, action) => {
            state.userInfo = action.payload
        },
    },
})

export const { lock, login, userInfo } = AuthSlice.actions

export default AuthSlice.reducer