import isNull from "./NullCheck";

const TimeFormatter = (value) => {
  // 24 to 12 hours format
  if (isNull(value)) {
    return "";
  } else {
    let timeSplit = value.split(":"),
      hours,
      minutes,
      meridian;
    hours = parseInt(timeSplit[0]);
    minutes = parseInt(timeSplit[1]);
    if (hours > 12) {
      meridian = "PM";
      hours -= 12;
    } else if (hours < 12) {
      meridian = "AM";
      if (hours === 0) {
        hours = 12;
      }
    } else {
      meridian = "PM";
    }

    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      
      minutes = `0${minutes}`;
    }
    let tagarttedValue = hours + ":" + minutes + " " + meridian;
    return tagarttedValue;
  }
};
export default TimeFormatter;
