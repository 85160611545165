import { createSlice } from '@reduxjs/toolkit'

let initialState = {
    moduleNames: []
}
export const AdminModuleSlice = createSlice({
    name: 'adminModules',
    initialState,
    reducers: {
        moduleName: (state, action) => {
            state.moduleNames = action.payload
        },
    },
})

export const { moduleName } = AdminModuleSlice.actions

export default AdminModuleSlice.reducer