import React from 'react';
import styles from './InputVariants.module.css';
import Spinner from '../../../Common/Spinner';
const InputVariant1 = (props) => {
  return (
    <div className={styles.varriant1}>
      <input
        onKeyDown={props.handleOnKeyDown}
        disabled={props.variantDisabled}
        onChange={props.handleChange}
        value={props.searchValue}
        id={`superSearchInputField_${props.variantID}`}
        type="text"
        autoComplete="off"
        className={styles.varriant1_input}
        placeholder={props.variantPlaceholder}
      />
      {
        props.searchValue ? (
          <button className={styles.varriant1_icon} style={{ background: props.variantThemeColor }} onClick={props.resetComponent}   >
            {props.loading ? <Spinner size={14} /> : props.variantCrossIcon}
          </button>
        ) : (
          <button disabled={props.variantDisabled} className={styles.varriant1_icon} style={{ background: props.variantThemeColor }}>
            {props.variantSearchIcon}
          </button>
        )
      }
    </div>
  );
};

export default InputVariant1;
