import React, { useState, useEffect } from "react";
import { DataGrid } from "../../../Common/DataGrid/DataGrid";
import { PrivateApiCall } from "../../../Api/PrivateApiCall";
import DeletePopup from "../../../Common/DeletePopup";
import ExportButtons from "../../../Common/ExportButtons";
import SearchHeader from "../../../Common/SearchHeader";
import { ErrorHandlingMessage } from "../../../Utils/error-handling-message";
import { ShortKey } from "../../../Utils/ShortKey";
import Audit from "../../../Audit/Audit";
import PageLoader from "../../../Common/PageLoader";
import { PublicApiCall } from "../../../Api/PublicApiCall";
import { toast } from "react-toastify";
import toaster from "../../../Utils/toaster";
import { enumUtil } from "../../../Utils/enumUtil";

const ViewProviderEnrollmentSetup = ({
  openAddNewProv,
  addNewProviderEnrollmentPopup,
  isFormSaved,
  setIsFormSaved,
}) => {
  const REACT_APP_AuthenticationService =
    process.env.REACT_APP_AuthenticationService;

  const [providerArray, setProviderArray] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [provID, setProvID] = useState(null);
  const [enrollmentPopup, setEnrollmentPopup] = useState(false);
  const [searchdata, setSearchdata] = useState({
    name: "",
    lastName: "",
    firstName: "",
    npi: "",
    taxonomyCode: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAudit, setIsShowAudit] = useState(false);
  const [moduleId, setModuleId] = useState("");
  const [insuranceInformation, setInsuranceInformation] = useState({
    payerName: "",
    payerId: "",
  });
  const [eventType, setEventType] = useState("Both");
  const [searchModal, setSearchModal] = useState({
    param_list: [
      {
        key: "string",
        value: "string",
      },
    ],
    practiceId: 0,
    perPage: 10,
    pageNo: 1,
    totalCount1: 0,
    totalCount2: 0,
    totalPages: 0,
    totalCount: 0,
    isExportCall: false,
  });
  useEffect(() => {
    if (!addNewProviderEnrollmentPopup) {
      findProvider();
    }
  }, [addNewProviderEnrollmentPopup, searchModal.pageNo, searchModal.perPage]);
  useEffect(() => {
    if (isFormSaved) {
      findProvider();
      setIsFormSaved(false);
    }
  }, [isFormSaved]);

  const findProvider = () => {
    const newModel = { ...searchModal };
    let searchmodel = [];
    let checkarray = false;
    for (const key in searchdata) {
      if (searchdata[key]) {
        checkarray = true;
        searchmodel = [...searchmodel, { key: key, value: searchdata[key] }];
      }
    }
    if (checkarray) {
      newModel.param_list = searchmodel;
    }

    setIsLoading(true);
    PrivateApiCall(
      `${REACT_APP_AuthenticationService}/Provider/Findprovider`,
      "post",
      newModel
    )
      .then((res) => {
        setIsLoading(false);
        setProviderArray(res.data);
        setSearchModal({
          ...searchModal,
          totalCount: res.totalCount,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        ErrorHandlingMessage(error);
        // console.log(error)
      });
  };
  const onProviderSearchChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setSearchdata((prev) => ({
      ...prev,
      [name]: value,
    }));
    // setSearchDataBase(value)
  };
  const handlePerRowsChange = (rowPerPage, pageno) => {
    setSearchModal({
      ...searchModal,
      pageNo: pageno,
      perPage: rowPerPage,
    });
  };
  const handlePageChange = (pageno) => {
    setSearchModal({
      ...searchModal,
      pageNo: pageno,
    });
  };
  const openPopup = (id) => {
    setProvID(id);
    setIsDeletePopup(true);
  };
  const getRefId = (e, editId, editName) => {
    openAddNewProv(true, editId, editName);
  };
  const handleProviderEnrollment = (row) => {
    if (
      !row.taxId ||
      !row.npi ||
      !row.firstName ||
      !insuranceInformation.payerId
    ) {
      toaster(
        `Fields are missing ${!row.taxId ? "Tax ID" : ""} ${
          !row.npi ? "NPI" : ""
        } ${!row.firstName ? "First Name" : ""} `,
        enumUtil.enumtoaster.error
      );
      return;
    }
    let initialModel = {
      accountKey: "",
      payerid: insuranceInformation.payerId,
      enroll_type: "elig",
      prov_taxid: row.taxId,
      prov_npi: row.npi,
      prov_name_l: row.lastName,
      prov_name_f: row.firstName,
      prov_name_m: row.name,
      contact: "",
      contact_title: "",
      contact_email: "",
      contact_phone: "",
      contact_fax: "",
      prov_id: row.id,
      prov_addr_1: row.address1,
      prov_addr_2: row.address2,
      prov_city: "",
      prov_state: "",
      prov_zip: "",
    };
    setIsLoading(true);
    PrivateApiCall(
      `${process.env.REACT_APP_TC_PMS}/ProviderEnrollment/EnrollProviderPayer`,
      "POST",
      { ...initialModel }
    )
      .then((res) => {
        setIsLoading(false);
        if (JSON.parse(res)?.link) {
          if (JSON.parse(res)?.link[0]?.url) {
            window.open(JSON.parse(res)?.link[0]?.url, "_blank").focus();
            // addNewProviderEnrollmentPopup(JSON.parse(res)?.link[0]?.url);
          }
        } else if (JSON.parse(res).error.error_mesg) {
          toaster(JSON.parse(res).error.error_mesg, enumUtil.enumtoaster.error);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  let providerColumn = [
    {
      name: "Name",
      width: 240,
      key: "name",
      resizable: true,
      sortable: true,
      // formatter({ row }) {
      //   return (
      //     <>
      //       <span
      //         onClick={(e) => getRefId(e, row.id, row.name)}
      //         className="anchor-tag"
      //       >
      //         {row.name}
      //       </span>
      //     </>
      //   );
      // },
    },
    {
      name: "NPI",
      key: "npi",
      resizable: true,
      sortable: true,
      width: 150,
    },
    {
      name: "Tax ID",
      key: "taxId",
      resizable: true,
      sortable: true,
      width: 150,
    },
    {
      name: "TAXONOMY CODE",
      key: "taxonomyCode",
      resizable: true,
      sortable: true,
      width: 150,
    },
    {
      name: "PHONE #",
      key: "phoneNumber",
      resizable: true,
      sortable: true,
      width: 150,
    },
    {
      name: "ADDRESS",
      key: "address",
      resizable: true,
      sortable: true,
    },
    {
      name: "Eligibility",
      width: 100,
      formatter({ row }) {
        return (
          <>
            <button
              onClick={() => handleProviderEnrollment(row)}
              disabled={insuranceInformation.payerId ? false : true}
              className="d-flex justify-content-center btn-secondary border-0  align-items-center mt-1"
              style={{
                opacity: insuranceInformation.payerId ? "1" : ".3",
                cursor: insuranceInformation.payerId ? "pointer" : "initial",
                padding: "2px 6px",
                height: "20px",
                outline: "none",
                border: "1px solid gray",
                fontSize: "12px",
                margin: "0 auto",
              }}
            >
              Enroll
            </button>
            {/* <i
              className="fa fa-trash btn btn-sm"
              onClick={() => openPopup(row.id)}
              aria-hidden="true"
            ></i>
            <i
              className="fa fa-history btn btn-sm mr-3"
              onClick={() => toggleAuditHandler(row.id, "Both")}
              aria-hidden="true"
            ></i> */}
          </>
        );
      },
    },
  ];

  //Audit function
  const toggleAuditHandler = (moduleId, eventType) => {
    setModuleId(moduleId);
    setEventType(eventType);
    setIsShowAudit((prevState) => !prevState);
  };

  const clearInput = () => {
    setSearchdata({
      ...searchdata,
      name: "",
      lastName: "",
      firstName: "",
      npi: "",
      taxonomyCode: "",
    });
  };
  const enterHandler = (e) => {
    if (e.charCode === 13) {
      findProvider(e);
    }
  };
  const logKey = (e) => {
    if (ShortKey(e, e.keyCode) === "add") {
      openAddNewProv(true, null);
    }
    if (ShortKey(e, e.keyCode) === "find") {
      findProvider(e);
    }
    if (ShortKey(e, e.keyCode) === "close") {
      clearInput(e);
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", logKey);
    return () => {
      window.removeEventListener("keydown", logKey);
    };
  }, [openAddNewProv]);
  return (
    <>
      {isLoading && <PageLoader />}

      <div className="row clearfix">
        {isDeletePopup ? (
          <DeletePopup
            id={provID}
            endpoint={`${REACT_APP_AuthenticationService}/Delete/DeleteApi`}
            moduleName={"Provider"}
            dbType={"clientdb"}
            close={() => {
              findProvider();
              setIsDeletePopup(false);
            }}
          />
        ) : undefined}

        {isShowAudit ? (
          <Audit
            moduleName={"Provider"}
            DBType={"client"}
            moduleId={moduleId}
            eventType={eventType}
            toggleAuditHandler={toggleAuditHandler}
          />
        ) : null}

        <div className="col-md-12">
          <div className="card">
            <SearchHeader
              heading="Provider Enrollment Search"
              // onClickHandler={() => openAddNewProv(true, null)}
              // toggleAuditHandler={toggleAuditHandler}
            />
            <div className="body">
              <div className="row clearfix">
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="name" className="control-label">
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      value={searchdata.name}
                      onChange={onProviderSearchChange}
                      onKeyPress={(e) => enterHandler(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="name" className="control-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      className="form-control"
                      value={searchdata.lastName}
                      onChange={onProviderSearchChange}
                      onKeyPress={(e) => enterHandler(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="name" className="control-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      className="form-control"
                      value={searchdata.firstName}
                      onChange={onProviderSearchChange}
                      onKeyPress={(e) => enterHandler(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="name" className="control-label">
                      NPI
                    </label>
                    <input
                      type="text"
                      id="npi"
                      name="npi"
                      className="form-control"
                      value={searchdata.npi}
                      onChange={onProviderSearchChange}
                      onKeyPress={(e) => enterHandler(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="name" className="control-label">
                      Taxonomy Code
                    </label>
                    <input
                      type="text"
                      id="taxonomyCode"
                      name="taxonomyCode"
                      className="form-control"
                      value={searchdata.taxonomyCode}
                      onChange={onProviderSearchChange}
                      onKeyPress={(e) => enterHandler(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="form-group formbuttonalign01">
                    <button
                      id="searchbtn"
                      type="button"
                      className="btn btn-primary"
                      onClick={() => findProvider()}
                    >
                      Search
                    </button>
                    <button
                      id="searchbtn"
                      type="button"
                      className="btn btn-danger ml-2"
                      onClick={() => clearInput()}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <ExportButtons
              insuranceFilter={(insurance) => {
                if (insurance) {
                  setInsuranceInformation({
                    payerName: insurance.payerName,
                    payerId: insurance.payerId,
                  });
                } else {
                  setInsuranceInformation({ payerName: "", payerId: "" });
                }
              }}
              heading="Provider Enrollment Search Result"
            />
            <DataGrid
              columns={providerColumn} //required
              rows={providerArray} //required
              isLoading={isLoading} //required
              manualPagination={true}
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
              pagination={searchModal}
              customMessage="There are no records to display"
              dynamicClass={"billingheightcalcCCDAtaskLogReports"}
              mainDivClass="heightper"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewProviderEnrollmentSetup;
