import { configureStore } from '@reduxjs/toolkit'
import AuthSlice from './Slices/AuthSlice';
import AdminModuleSlice from './Slices/AdminModuleSlice';

export const store = configureStore({
  reducer: {
    auth: AuthSlice,
    adminModules: AdminModuleSlice,
  },
  devTools: true
})


export default store;
