import React from 'react'
export const MyInputField = (props) => {
    // common props that are sent are below
    // if needed any else please add below
    const {
        isInputLabel,
        inputLabelText,
        inputLabelClassName,
        inputId,
        inputName,
        inputClassName,
        inputAutoComplete,
        inputType,
        inputValue,
        inputOnChange,
        inputOnKeyPress,
        inputOnKeyDown,
        inputOnInputFn,
        inputOnBlur,
        inputMaxLength,
        inputDisbaled,
        intputPlaceholder,
        inputRef,
    } = props

    return (
        <>
            {isInputLabel ?
                <label
                    htmlFor={inputName ? inputName : "myInputField"}
                    className={inputLabelClassName ? inputLabelClassName : ""}
                >
                    {inputLabelText}
                </label>
                :
                undefined
            }
            <input
                id={inputId ? inputId : 'myInputField'}
                name={inputName ? inputName : 'myInputField'}
                ref={inputRef ? inputRef : undefined}
                className={inputClassName ? inputClassName : "form-control"}
                autoComplete={inputAutoComplete ? inputAutoComplete : "nope"}
                type={inputType ? inputType : "text"}
                value={inputValue ? inputValue : ''}
                placeholder={intputPlaceholder ? intputPlaceholder : ''}
                onChange={inputOnChange ? inputOnChange : undefined}
                onKeyPress={inputOnKeyPress ? inputOnKeyPress : undefined}
                onKeyDown={inputOnKeyDown ? inputOnKeyDown : undefined}
                onInput={inputOnInputFn ? inputOnInputFn : undefined}
                maxLength={inputMaxLength ? inputMaxLength : undefined}
                disabled={inputDisbaled ? inputDisbaled : undefined}
                onBlur={inputOnBlur ? inputOnBlur : undefined}
            />
        </>
    )
}