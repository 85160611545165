import React from 'react'
import Loader from './Loader'
export const MyButton = (props) => {
    const {
        buttonId,
        buttonLabel,
        buttonType,
        buttonClasses,
        buttonDisabled,
        buttonOnchange,
        disabledAndLoading
    } = props
    return (
        <button
            id={buttonId ? buttonId : 'myButton'}
            type={buttonType ? buttonType : 'button'}
            className={buttonClasses ? buttonClasses : 'btn btn-primary'}
            disabled={buttonDisabled ? buttonDisabled : disabledAndLoading}
            onClick={buttonOnchange ? buttonOnchange : undefined}
        >
            {buttonLabel}
            {
                disabledAndLoading ?
                    <Loader small={true} />
                    :
                    undefined
            }
        </button>
    )
}