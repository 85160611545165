import React, { useState } from "react";
import MyAutocomplete from "./MyAutocomplete/MyAutoComplete";
import SuperSearch from "./SuperSearch/SuperSearch";

const ExportButtons = ({ heading, insuranceFilter }) => {
  const [insurancePlan, setInsurancePlan] = useState({
    payerName: "",
    payerId: "",
  });

  return (
    <div className="header d-flex justify-content-between">
      <h2>{heading}</h2>
      <div>
        <ul className="header-dropdown dropdown dropdown-animated scale-left">
          <li>
            <a
              href="#/"
              onClick={(e) => e.preventDefault()}
              className="full-screen"
            >
              <i className="icon-size-fullscreen" />
            </a>
          </li>
        </ul>
        {insuranceFilter && (
          <div className="d-flex mr-4">
            <SuperSearch
              variantType="variant1"
              themeColor="#F45D48"
              extraStyling={{ width: "300px" }}
              identifier="testing"
              ApiLink={`${process.env.REACT_APP_AuthenticationService}/InsurancePlan/FindInsurancePlanAdvance/`}
              method="post"
              postBody={{
                param_list: [
                  { key: "PLANNAME", value: insurancePlan.payerName },
                ],
                practiceId: 0,
                perPage: 10,
                pageNo: 1,
                totalPages: 0,
                totalCount: 0,
                isExportCall: false,
              }}
              responseStructure={"products"}
              placeholder={"Payer Search..."}
              tableHeight="120"
              // tableWidth="300px"
              getSelectedData={(data) => {
                setInsurancePlan(
                  data?.payerName
                    ? { payerName: data.payerName, payerId: data.payerId }
                    : { payerName: "", payerId: "" }
                );
                insuranceFilter(data || "");
              }}
              onChange={(v) =>
                setInsurancePlan({ ...insurancePlan, payerName: v })
              }
              searchValue={insurancePlan.payerName}
              tableData={[
                { columName: "Payer Name", bindingValue: "payerName" },
                {
                  columName: "Payer ID",
                  bindingValue: "payerId",
                  columnWidth: 70,
                },
              ]}
              allowHeaders
            ></SuperSearch>
            <input
              value={insurancePlan.payerId}
              disabled
              style={{ width: "80px", fontSize: "11px" }}
              className="form-control ml-1"
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default ExportButtons;
