import axios from "axios";

const PublicApiCall = (url, method, payload) => {
  const options = {
    method,
    url: `${process.env.REACT_APP_AuthenticationService}${url}`,
    data: payload,
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Headers": "*",
    },
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then((res) => {
        resolve(res.data.dataModel);
      })
      .catch((error) => {
        if (error && error.response) {
          if (error.response.data && error.response.data.isError) {
            reject({
              message: error.response.data.responseMessage,
              statusCode: error.response.data.statusCode,
            });
          } else {
            reject({
              message: "Something went wrong, Please re-login",
              statusCode: 500,
            });
          }
        } else {
          reject({
            message: "Something went wrong, Please re-login",
            statusCode: 500,
          });
        }
      });
  });
};

export { PublicApiCall };
