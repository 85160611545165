import React, { useState } from "react";
import { PrivateApiCall } from "../Api/PrivateApiCall";
import { enumUtil } from "../Utils/enumUtil";
// import { ErrorHandlingMessage } from "../Utils/error-handling-message";
import toaster from "../Utils/toaster";
import { MyButton } from "./MyButton";

export default function DeletePopup({ id, endpoint, close, moduleName, dbType }) {
  const [isLoading, setIsLoading] = useState(false);
  const [resonError, setResonError] = useState("");
  const [deleteModal, setDeleteModal] = useState({
    pkValues: [
      ""
    ],
    moduleName: "",
    dbType: "",
    inActive: true,
    practiceId: 0,
    inActiveReason: ""
  });

  const onChangeReason = (e) => {
    let value = e.target.value;
    setDeleteModal((prev) => ({
      ...prev,
      pkValues: [`${id}`],
      moduleName: moduleName,
      dbType: dbType,
      inActiveReason: value,

    }));
  };

  const onDelete = () => {
    if (deleteModal.inActiveReason === "") {
      setResonError("Reason required!");
      return;
    }
    setResonError("");
    setIsLoading(true);
    PrivateApiCall(endpoint, "post", deleteModal)
      .then((res) => {
        toaster("Delete Successfully", enumUtil.enumtoaster.success);
        setIsLoading(false);
        close(false);
      })
      .catch((error) => {
        toaster("Delete Unsuccessfully", enumUtil.enumtoaster.error);
        setIsLoading(false);
        close(false);
      });
  };

  return (
    <div
      className="modal fade bd-example-modal-lg show d-block popBackground"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div
          style={{
            position: "relative",
          }}
          className="modal-content modal-position"
        >
          <div className="modal-header ">
            <div
              style={{
                lineHeight: "0.2",
              }}
            >
              <h5 className="modal-header-tittle">Why you want to delete</h5>
            </div>
            <div>
              <a
                href="#/"
                onClick={(e) => {
                  e.preventDefault()
                  close(false)
                }}
                data-dismiss="modal"
                aria-label="Close"
                className="modalCloseButtonHeader"
              >
                x
              </a>
            </div>
          </div>
          <div className="modal-body">
            <div className="body">
              <div className="row clearfix">
                <div className="col-sm-12">
                  <div className="form-group">
                    <textarea
                      style={{
                        width: "100%",
                        height: "200px",
                      }}
                      placeholder="Reason..."
                      value={deleteModal.inActiveReason}
                      onChange={onChangeReason}
                    />
                    {resonError && <p className="text-red">{resonError}</p>}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <MyButton
                buttonId="closeDBBtn"
                buttonLabel="Close"
                buttonClasses="btn - btn-primary center p-l-2 modal-addButtonCustom  mr-2"
                buttonOnchange={() => close(false)}
              />
              <MyButton
                buttonId="deleteDBBtn"
                buttonLabel="Delete"
                buttonClasses="btn - btn-success center p-l-2 modal-addButtonCustom  mr-2"
                buttonOnchange={onDelete}
                disabledAndLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
