import React, { useState, useEffect } from "react";
import { PrivateApiCall } from "../../../Api/PrivateApiCall";
import { ErrorHandlingMessage } from "../../../Utils/error-handling-message";
import ExportButtons from "../../../Common/ExportButtons";
import { DataGrid } from "../../../Common/DataGrid/DataGrid";
import DeletePopup from "../../../Common/DeletePopup";
import { enumUtil } from "../../../Utils/enumUtil";
import toaster from "../../../Utils/toaster";
import PageLoader from "../../../Common/PageLoader";
import { ShortKey } from "../../../Utils/ShortKey";
import AdjustmentActionAdd from "./AdjustmentActionAdd";
const groupCodes = [
    { value: '', display: '' },
    { value: 'CO', display: 'CO' },
    { value: 'OA', display: 'OA' },
    { value: 'PI', display: 'PI' },
    { value: 'PR', display: 'PR' },
]
const status = [
    { value: "", display: "Please Select" },
    { value: "Denial", display: "Denial" },
    { value: "WriteOff", display: "WriteOff" },
];
const AdjustmentAction = () => {
    const REACT_APP_Common = process.env.REACT_APP_Common;
    const REACT_APP_AuthenticationService = process.env.REACT_APP_AuthenticationService;
    const [addPos, setaddPos] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [posArray, setposeArray] = useState([]);

    const [isDeletePopup, setIsDeletePopup] = useState(false);
    const [posID, setposID] = useState(null);
    const [editID, seteditID] = useState("");
    const [arrayWithCientData, setarrayWithCientData] = useState([]);
    const [disableAttribute, setdisableAttribute] = useState(false);
    const [searchdata, setsearchData] = useState({
        status: "",
        description: "",
        reasonCode: "",
        remarkCode: "",
        // rejectionCode: "",
        groupCode: ""

    });
    const [emptyModal, setemptyModal] = useState(false);
    const [searchModal, setSearchModal] = useState({
        param_list: [
            {
                key: "",
                value: "",
            },
        ],
        practiceId: 0,
        perPage: 10,
        pageNo: 1,
        totalCount1: 0,
        totalCount2: 0,
        totalPages: 0,
        totalCount: 0,
        isExportCall: false,
    });

    const onPosSearchChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        setsearchData((prev) => ({
            ...prev,
            [name]: value,
        }));
        // setSearchDataBase(value)
    };
    const AddNew = () => {
        setaddPos(true);
    };
    const toggleAddNewScreen = () => {
        setaddPos(false);
        seteditID(null);
        findAdjustmentAction();
    };

    useEffect(() => {
        if (emptyModal === true) {
            return;
        }
        findAdjustmentAction();
    }, [searchModal.pageNo, searchModal.perPage, emptyModal]);

    const findAdjustmentAction = () => {
        const newModel = { ...searchModal };
        let searchmodel = [];
        let checkarray = false;
        for (const key in searchdata) {
            if (searchdata[key]) {
                checkarray = true;
                searchmodel = [...searchmodel, { key: key, value: searchdata[key] }];
            }
        }
        if (checkarray) {
            newModel.param_list = searchmodel;
        }

        setIsLoading(true);
        PrivateApiCall(
            `${REACT_APP_AuthenticationService}/PaymentAdjustmentActionCode/FindByAdvance`,
            "post",
            newModel
        )
            .then((res) => {
                setIsLoading(false);
                setposeArray(res.data);
                setSearchModal({
                    ...searchModal,
                    perPage: res.perPage,
                    pageNo: res.pageNo,
                    totalCount: res.totalCount,
                });
            })
            .catch((error) => {
                setIsLoading(false);
                ErrorHandlingMessage(error);
                // console.log(error)
            });
    };
    const openPopup = (id) => {
        setposID(id);
        setIsDeletePopup(true);
    };

    const getRefId = (editCode) => {
        setaddPos(true);
        seteditID(editCode);
    };
    const clearInput = () => {
        setemptyModal(true);
        setsearchData({
            status: "",
            groupCode: "",
            description: "",
            reasonCode: "",
            remarkCode: ""
        });
    };
    const moveThisRowToClient = (e, row) => {
        let chk = e.target.checked;
        console.log(row)
        setdisableAttribute(false);
        let newpos = [...posArray];
        let newClientDataArray = [...arrayWithCientData];
        let newClientFlag = row.clientDbRecordFlag === 1 ? 1 : 0;
        let newCommonFlag = row.commonDbRecordFlag === 1 ? 1 : 0;
        let newRow = {
            ...row,
            clientDbRecordFlag: newClientFlag,
            commonDbRecordFlag: newCommonFlag,
        };
        if (chk === true) {
            let posUpdatedFlagArray = newpos.map((pos) =>
                pos.id === row.id
                    ? { ...pos, clientDbRecordFlag: 1, chkIt: true }
                    : pos
            );
            newClientDataArray.push({
                ...newRow,
                addedBy: "",
                type: "",
            });
            setarrayWithCientData(newClientDataArray);
            setposeArray(posUpdatedFlagArray);
        } else if (chk === false) {
            let posUpdatedFlagArray = newpos.map((pos) =>
                pos.id === row.id ? { ...pos, clientDbRecordFlag: 0 } : pos
            );
            let filteredArray = newClientDataArray.filter(
                (client) => client.id !== row.id
            );
            setarrayWithCientData(filteredArray);
            setposeArray(posUpdatedFlagArray);
        } else {
        }
    };




    let posColumn = [
        {
            name: "",
            key: "clientDbRecordFlag",
            resizable: true,
            width: 100,
            sortable: true,
            formatter({ row }) {

                return (
                    <>
                        <input
                            type="checkbox"
                            onClick={(e) => moveThisRowToClient(e, row)}
                            checked={row.clientDbRecordFlag === 1 ? "checked" : ""}
                            disabled={
                                disableAttribute === true
                                    ? row.clientDbRecordFlag === 1
                                        ? true
                                        : false
                                    : disableAttribute === false && row.chkIt === true
                                        ? false
                                        : disableAttribute === false && row.clientDbRecordFlag === 0
                                            ? false
                                            : true
                            }
                        />
                    </>
                );
            },
        },
        {
            name: "Group Code",
            key: "groupCode",
            resizable: true,
            width: 100,
            sortable: true,

            formatter({ row }) {
                return (
                    <>
                        <span onClick={(e) => getRefId(row.id)} className="anchor-tag">
                            {row.groupCode}
                        </span>
                    </>
                );
            },
        },

        {
            name: "ReasonCode",
            key: "reasonCode",
            resizable: true,
            sortable: true,
        },
        {
            name: "Remark Code",
            key: "remarkCode",
            resizable: true,
            sortable: true,
        },
        {
            name: "Status",
            key: "status",
            resizable: true,
            sortable: true,
        },
        {
            name: "Description",
            key: "description",
            resizable: true,
            sortable: true,
        },

        {
            name: "Action",
            resizable: true,
            width: 150,
            formatter({ row }) {
                return (
                    <>
                        <i
                            className="fa fa-trash btn btn-sm"
                            onClick={() => openPopup(row.id)}
                            aria-hidden="true"
                        ></i>

                    </>
                );
            },
        },
    ];
    const handlePerRowsChange = (rowPerPage, pageno) => {
        setSearchModal({
            ...searchModal,
            pageNo: pageno,
            perPage: rowPerPage,
        });
    };
    const handlePageChange = (pageno) => {
        setSearchModal({
            ...searchModal,
            pageNo: pageno,
        });
    };

    // const KeyDown = (e) => {
    //     if (e.keyCode === 13) {
    //         findAdjustmentAction();
    //     }
    // };
    const logKey = (e) => {
        if (ShortKey(e, e.keyCode) === "add") {
            AddNew();
        }
        if (ShortKey(e, e.keyCode) === "find") {
            findAdjustmentAction(e);
        }
        if (ShortKey(e, e.keyCode) === "close") {
            clearInput(e);
        }
    };
    const moveToClient = () => {
        setIsLoading(true);
        PrivateApiCall(
            `${REACT_APP_AuthenticationService}/PaymentAdjustmentActionCode/SavePaymentAdjustmentActionCodeList`,
            "post",
            arrayWithCientData
        )
            .then((res) => {
                findAdjustmentAction();
                setarrayWithCientData([]);
                toaster("Data save Successfully", enumUtil.enumtoaster.success);
                setIsLoading(false);
            })
            .catch((err) => {
                toaster("Something went wrong", enumUtil.enumtoaster.error);
                setIsLoading(false);
            });
    };
    useEffect(() => {
        window.addEventListener("keydown", logKey);
        return () => {
            window.removeEventListener("keydown", logKey);
        };
    }, [AddNew]);

    return (
        <>

            {isLoading ? <PageLoader /> : null}
            <div className="row clearfix">
                {isDeletePopup ? (
                    <DeletePopup
                        id={posID}
                        endpoint={`${REACT_APP_AuthenticationService}/Delete/DeleteApi`}
                        moduleName={"RemarkCode"}
                        dbType={"commondb"}
                        close={() => {
                            findAdjustmentAction();
                            setIsDeletePopup(false);
                        }}
                    />
                ) : undefined}
                {/* {isShowAudit ? (
                    <Audit
                        moduleName={"RemarkCode"}
                        DBType={"common"}
                        moduleId={moduleId}
                        eventType={eventType}
                        toggleAuditHandler={toggleAuditHandler}
                    />
                ) : null} */}

                <div className="col-md-12">
                    <div className="card">
                        <div className="header">
                            <div className="cardheading">
                                <h2>Payment Adjustment Action Search</h2>
                            </div>

                            <div className="form-group   floatright margintop-5">
                                {/* <button
                                    id="viewAuditBtn"
                                    name="viewAuditBtn"
                                    className="btn btn-warning"
                                    onClick={() => toggleAuditHandler("", "Deleted")}
                                >
                                    View Audit
                                </button> */}

                                <button
                                    id="addNewBtn"
                                    name="addNewBtn"
                                    className="btn btn-primary ml-1"
                                    onClick={() => AddNew()}
                                >
                                    Add New
                                </button>
                            </div>
                            {arrayWithCientData?.length > 0 ? (
                                <button
                                    id="addNewBtn"
                                    name="addNewBtn"
                                    className="btn btn-primary floatright margintop-5 mr-2"
                                    onClick={() => moveToClient()}
                                >
                                    Move to client
                                </button>
                            ) : null}
                            {/* {arrayWithCientData?.length > 0 ? (
                                <button
                                    id="addNewBtn"
                                    name="addNewBtn"
                                    className="btn btn-primary floatright margintop-5 mr-2"
                                    onClick={() => moveToClient()}
                                >
                                    Move to client
                                </button>
                            ) : null} */}
                        </div>
                        <div className="body">
                            <div className="row clearfix">
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="name" className="control-label">
                                            Status
                                        </label>

                                        <select
                                            className="form-control"
                                            name="status"
                                            id="status"
                                            value={searchdata?.status}
                                            onChange={onPosSearchChange}
                                        >
                                            {status.map((status, index) => <option value={status.value} key={index}>{status.display}</option>)}

                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="name" className="control-label">
                                            Group Code
                                        </label>

                                        <select
                                            className="form-control"
                                            name="groupCode"
                                            id="groupCode"
                                            value={searchdata?.groupCode}
                                            onChange={onPosSearchChange}
                                        >
                                            {/* <option value={null}>Please Select</option> */}
                                            {groupCodes.map((el) => {
                                                return (
                                                    <option key={el.value} value={el.value}>
                                                        {el.display}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                {/* <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label className="" htmlFor="name">
                                            Reason Code
                                        </label>
                                        <input
                                            className="form-control"
                                            maxlength="5"
                                            type="text"
                                            name="reasonCode"
                                            value={searchdata?.reasonCode}
                                            onChange={onPosSearchChange}
                                        />

                                    </div>
                                </div>*/}
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label className="" htmlFor="name">
                                            Remark Code
                                        </label>
                                        <input
                                            className="form-control"
                                            maxlength="5"
                                            type="text"
                                            name="remarkCode"
                                            value={searchdata?.remarkCode}
                                            onChange={onPosSearchChange}
                                        />

                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label className="" htmlFor="name">
                                            Reason Code
                                        </label>
                                        <input
                                            className="form-control"
                                            maxlength="5"
                                            type="text"
                                            name="reasonCode"
                                            value={searchdata?.reasonCode}
                                            onChange={onPosSearchChange}
                                        />

                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label className="" htmlFor="name">
                                            Description
                                        </label>
                                        <input
                                            className="form-control"
                                            maxlength="5"
                                            type="text"
                                            name="description"
                                            value={searchdata?.description}
                                            onChange={onPosSearchChange}
                                        />

                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="form-group formbuttonalign">
                                        <button
                                            id="searchbtn"
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => findAdjustmentAction()}
                                        >
                                            Search
                                        </button>
                                        <button
                                            id="searchbtn"
                                            type="button"
                                            className="btn btn-danger ml-2"
                                            onClick={() => clearInput()}
                                        >
                                            Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="card">
                            <ExportButtons heading="Adjustment Action Search Result" />
                            <DataGrid
                                columns={posColumn} //required
                                rows={posArray} //required
                                isLoading={isLoading} //required
                                manualPagination={true}
                                handlePerRowsChange={handlePerRowsChange}
                                handlePageChange={handlePageChange}
                                pagination={searchModal}
                                customMessage="There are no records to display"
                                dynamicClass={"billingheightTeamUserSetup"}
                                mainDivClass="heightPer"
                            />
                        </div>
                    </div>
                </div>
                {addPos ? (
                    <AdjustmentActionAdd
                        toggleAddNewScreen={toggleAddNewScreen}
                        findAdjustmentAction={findAdjustmentAction}
                        editID={editID}
                        setaddPos={setaddPos}
                    />
                ) : null}
            </div>
        </>
    );
};

export default AdjustmentAction;
