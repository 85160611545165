import React, { useState, useMemo, useEffect } from "react";
import Grid, { SelectColumn } from "react-data-grid";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import isNull from "../null-checking";
import DraggableHeaderRenderer from "./DraggableHeaderRenderer";
import DraggableRowRenderer from "./DraggableRowRenderer";
import Pagination from "./Pagination";

export const DataGrid = (props) => {
  //const {isDraggableColumn}=props
  const isDraggableColumn = true;
  const [loadingMoreRows, setLoadingMoreRows] = useState(false);
  const [sortColumns, setSortColumns] = useState([]); // columnKey,direction
  const [noRows, setNoRows] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [previousRows, setPreviousRows] = useState([]);
  const [tableColumns, setTableColumns] = useState(props.columns);
  const [rows, setRows] = useState([]);

  //const rows = props.rows?.slice(0)
  // let tableColumns = props.selectColumn
  //   ? [SelectColumn, ...props.columns]
  //   : props.columns
  //  check is column Date or not
  const checkFieldType = (columnCellValue) => {
    var date = Date.parse(columnCellValue);
    if (isNaN(date)) return false;
    else return true;
  };
  //////////////
  const onSortColumnsChange = (sortColumns) => {
    setSortColumns(sortColumns.slice(-1));
  };
  useEffect(() => {
    setRows(props.rows?.slice(0));
    //add selectCheckbox column in grid
    let columns = props.selectColumn
      ? [SelectColumn, ...props.columns]
      : props.columns;
    addTooltipOnCell(columns);
  }, [props.columns, props.rows]);
  const addTooltipOnCell = (columns) => {
    // if column already have formatter than tooltip is not added
    let newColumns = columns.map((column) => {
      if (!column.formatter) {
        column = {
          ...column,
          resizable: true,
          sortable: true,
          formatter({ row }) {
            return <span title={row[column.key]}>{row[column.key]}</span>;
          },
        };
      }
      return { resizable: true, sortable: true, ...column };
    });
    setTableColumns(newColumns);
  };
  const draggableColumns = useMemo(() => {
    const handleColumnsReorder = (sourceKey, targetKey) => {
      const sourceColumnIndex = tableColumns.findIndex(
        (c) => c.key === sourceKey.key
      );
      const targetColumnIndex = tableColumns.findIndex(
        (c) => c.key === targetKey
      );
      const reorderedColumns = [...tableColumns];

      reorderedColumns.splice(
        targetColumnIndex,
        0,
        reorderedColumns.splice(sourceColumnIndex, 1)[0]
      );

      setTableColumns(reorderedColumns);
    };
    const HeaderRenderer = (props, column) => {
      return (
        <DraggableHeaderRenderer
          // sortDirection={
          //   sortColumns.length > 0 ? sortColumns[0].direction : 'NONE'
          // }
          // sortColumnName={
          //   sortColumns.length > 0 ? sortColumns[0].columnKey : 'NONE'
          // }
          onColumnsReorder={handleColumnsReorder}
          column={column}
          {...props}
          //onSort={setSortColumns}
        />
      );
    };
    if (isDraggableColumn) {
      return tableColumns.map((c) => {
        if (c.key === "id" || c.key === "select-row" || c.key === "") {
          return c;
        } else {
          return { ...c, headerRenderer: (props) => HeaderRenderer(props, c) };
        }
      });
    } else {
      return tableColumns;
    }
  }, [tableColumns, sortColumns]);
  const addRowIndex = (rows) => {
    let Rows = rows.map((row, index) => {
      row = {
        ...row,
        rowIndex: index,
      };
      return row;
    });
    return Rows;
  };
  // call when rows and sortColumn values change
  const sortedRows = useMemo(() => {
    setLoadingMoreRows(false);
    if (sortColumns.length === 0) {
      if (props.onScrollPagination) {
        return [...previousRows, ...addRowIndex(rows)];
      } else {
        return addRowIndex(rows);
      }
    }
    let previousIndex = "";
    let nextIndex = "";
    const comparer = (a, b) => {
      let isDateColumn = false;
      //////// For Link Fields ////////
      if (
        !isNull(b[sortColumns[0].columnKey]) &&
        !isNull(b[sortColumns[0].columnKey].props)
      ) {
        isDateColumn = checkFieldType(b[sortColumns[0].columnKey].props.title);
        if (isDateColumn === true) {
          previousIndex = new Date(a[sortColumns[0].columnKey].props.title);
          nextIndex = new Date(b[sortColumns[0].columnKey].props.title);
        } else {
          previousIndex = isNull(a[sortColumns[0].columnKey].props.title)
            ? ""
            : a[sortColumns[0].columnKey].props.title;
          nextIndex = isNull(b[sortColumns[0].columnKey].props.title)
            ? ""
            : b[sortColumns[0].columnKey].props.title;
        }
        if (sortColumns[0].direction === "ASC") {
          return previousIndex > nextIndex ? 1 : -1;
        } else if (sortColumns[0].direction === "DESC") {
          return previousIndex < nextIndex ? 1 : -1;
        }
      }
      //////// For simple Fields////////
      else {
        isDateColumn = checkFieldType(b[sortColumns[0].columnKey]);
        if (isDateColumn === true) {
          previousIndex = new Date(a[sortColumns[0].columnKey]);
          nextIndex = new Date(b[sortColumns[0].columnKey]);
        } else {
          previousIndex = isNull(a[sortColumns[0].columnKey])
            ? ""
            : a[sortColumns[0].columnKey];
          nextIndex = isNull(b[sortColumns[0].columnKey])
            ? ""
            : b[sortColumns[0].columnKey];
        }
        if (sortColumns[0].direction === "ASC") {
          return previousIndex > nextIndex ? 1 : -1;
        } else if (sortColumns[0].direction === "DESC") {
          return previousIndex < nextIndex ? 1 : -1;
        }
      }
    };
    let filterRows = props.onScrollPagination
      ? [...previousRows, ...props.rows]
      : rows;
    const sortRows =
      sortColumns.direction === "NONE" ? filterRows : filterRows.sort(comparer);
    return addRowIndex(sortRows);
    //setsortRows(sortRows)
  }, [rows, sortColumns]);

  const rowKeyGetter = (row) => {
    return row.id;
  };
  const isAtBottom = ({ currentTarget }) => {
    return (
      currentTarget.scrollTop + 10 >=
      currentTarget.scrollHeight - currentTarget.clientHeight
    );
  };
  const handleScroll = async (event) => {
    let newRows = [...previousRows, ...props.rows];
    if (rows.length !== 0) {
      if (loadingMoreRows || !isAtBottom(event)) {
        setNoRows(false);
      } else if (newRows.length !== props.totalCount) {
        setLoadingMoreRows(true);
        // let getRowsLength = rows.length + props.pageSize
        setPreviousRows(newRows);
        setPageNo(pageNo + 1);
        await props.getMoreRows(pageNo + 1);
      } else {
        setLoadingMoreRows(false);
        setNoRows(true);
      }
    }
  };

  const rowRenderer = (key, rowRenderProps) => {
    function onRowReorder(fromIndex, toIndex) {
      const newRows = [...rows];
      newRows.splice(toIndex, 0, newRows.splice(fromIndex, 1)[0]);
      setRows(newRows);
      if (props.setUpdatedList) {
        props.setUpdatedList(newRows);
      }
    }
    return (
      <DraggableRowRenderer
        key={key}
        {...rowRenderProps}
        onRowReorder={onRowReorder}
      />
    );
  };
  // const summaryRows = rows.length !== 0 ? [rows] : null
  return (
    <React.Fragment>
      <div
        style={{ position: "relative" }}
        className={props.mainDivClass}
        id={props.id}
      >
        <DndProvider backend={HTML5Backend}>
          <Grid
            rowKeyGetter={props.isDraggableRows ? null : rowKeyGetter} // is required to get row key for selectcolumn
            summaryRows={props.fixedFooter ? props.summaryRows : null} // for add footer in grid // old version
            bottomSummaryRows={props.fixedFooter ? props.summaryRows : null} // for add footer in grid
            columns={isDraggableColumn ? draggableColumns : tableColumns}
            rows={sortedRows}
            sortColumns={sortColumns}
            onSortColumnsChange={onSortColumnsChange} // set column and  direction to sortColumns
            onRowClick={props.onRowClick}
            selectedRows={props.selectedRows}
            onSelectedRowsChange={props.onSelectedRowsChange}
            onScroll={props.onScrollPagination ? handleScroll : null}
            style={{
              width: props.width ? props.width : "100%",
              height: rows.length === 0 ? "100px" : "100%",
              maxHeight: props.height ? props.height : "330px",
              overflowY:
                !props.onScrollPagination && rows.length <= 10 ? "scroll" : "scroll",//changes
              userSelect: "text",
              fontSize: "13px",
            }}
            rowHeight={props.rowHeight ? props.rowHeight : 30}
            className={props.dynamicClass ? props.dynamicClass : ""}
            rowClass={props.rowClass ? props.rowClass : undefined}
            onRowsChange={props.onRowsChange ? props.onRowsChange : undefined}
            headerRowHeight={props.headerRowHeight ? props.headerRowHeight : 30}
            components={
              props.isDraggableRows
                ? { rowRenderer: (r) => rowRenderer(r.rowIdx, r) }
                : null
            }
            renderers={props.isDraggableRows ? { rowRenderer } : null}
          />
        </DndProvider>
        {loadingMoreRows && (
          <div
            style={{
              width: " 180px",
              padding: "8px 16px",
              position: "absolute",
              bottom: "8px",
              right: "8px",
              color: "white",
              lineHeight: "35px",
              background: "rgb(0 0 0 / 0.6)",
            }}
          >
            Loading More Rows...
          </div>
        )}
        {noRows && (
          <div
            style={{
              width: " 180px",
              padding: "8px 16px",
              position: "absolute",
              bottom: "8px",
              right: "8px",
              color: "white",
              lineHeight: "35px",
              background: "rgb(0 0 0 / 0.6)",
            }}
          >
            No more rows
          </div>
        )}
        {/* {props.isLoading || rows.length === 0 ? (
          <div
            style={{
              position: "absolute",
              top: "50px",
              left: "50%",
              transform: "translate(-50%, -20%)",
            }}
          >
            <TableLoader
              isLoading={loadingMoreRows ? false : props.isLoading}
              dataLength={rows.length}
              customMessage={
                props.customMessage ? props.customMessage : undefined
              }
            />
          </div>
        ) : null} */}
        {props.manualPagination && rows.length > 0 ? (
          <Pagination
            handlePerRowsChange={props.handlePerRowsChange}
            handlePageChange={props.handlePageChange}
            pagination={props.pagination}
            totalRows={props.rows.length}
            loading={props.isLoading}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
};
