import { useDrag, useDrop } from 'react-dnd';

import { Row } from 'react-data-grid'

const DraggableRowRenderer=({
  rowIdx,
  isRowSelected,
  onRowReorder,
  ...props
})=> {
  const [{ isDragging }, drag] = useDrag({
    item: {  type: 'ROW_DRAG', index: rowIdx },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  const [{ isOver }, drop] = useDrop({
    accept: 'ROW_DRAG',
    drop({ index }) {
      onRowReorder(index, rowIdx);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  return (
   
    <Row
       ref={(ref) => {
        if (ref) {
          drag(ref.firstElementChild);
        }
        drop(ref);
      }}
      rowIdx={rowIdx}
      isRowSelected={isRowSelected}
      className={isDragging?'rowDraggingClassname':isOver?'rowOverClassname':undefined}
      {...props}
    />
 
  );
}
export default DraggableRowRenderer