import React from "react";
import AdjustmentAction from "../Pages/EDICodes/AdjustmentAction/AdjustmentAction";
import { ROUTES } from "./RoutesPath";
import ProviderEnrollment from "../Pages/Insurance/ProviderEnrollment/ProviderEnrollmentSetup";

const Login = React.lazy(() => import("../Auth/Login"));
const Dashboard = React.lazy(() => import("../Pages/Dashboard/Dashboard"));
const Client = React.lazy(() => import("../Pages/Admin/Client/Client"));

const Receiver = React.lazy(() =>
  import("../Pages/SubmissionSetup/Receiver/Receiver")
);
const Submitter = React.lazy(() =>
  import("../Pages/SubmissionSetup/Submitter/Submitter")
);

const Database = React.lazy(() => import("../Pages/Admin/Database/index"));
const User = React.lazy(() => import("../Pages/Admin/User/User"));
const Team = React.lazy(() => import("../Pages/Admin/Team/Team"));
const Practice = React.lazy(() => import("../Pages/Admin/Practice/index"));
const Settings = React.lazy(() => import("../Pages/Settings/index"));
const ExternalSetting = React.lazy(() =>
  import("../Pages/Admin/External Setting")
);
const ReferingProvider = React.lazy(() =>
  import("../Pages/Admin/RefferingProvider/index")
);
const Provider = React.lazy(() => import("../Pages/Admin/Provider/index"));
const Location = React.lazy(() => import("../Pages/Admin/Location/index"));
const TaxonomyCode = React.lazy(() =>
  import("../Pages/Setting/TaxonomyCode/Index")
);
const ZipCode = React.lazy(() => import("../Pages/Setting/ZipCode/Index"));
const Group = React.lazy(() => import("../Pages/Setting/Group/Index"));
const InsurancePlan = React.lazy(() =>
  import("../Pages/Insurance/InsurancePlan/InsurancePlan")
);
const InsurancePortal = React.lazy(() =>
  import("../Pages/Insurance/InsurancePortal/InsurancePortal")
);
const InsurancePlanAddress = React.lazy(() =>
  import("../Pages/Insurance/InsurancePlanAddress/InsurancePlanAddress")
);
const ICD = React.lazy(() => import("../Pages/Coding/ICD/Icd"));
const CPT = React.lazy(() => import("../Pages/Coding/CPT/Cpt"));
const Modifiers = React.lazy(() =>
  import("../Pages/Coding/Modifiers/Modifier")
);
const insuranceMapping = React.lazy(() =>
  import("../Pages/Insurance/InsuranceMapping/InsuranceMapping")
);
const POS = React.lazy(() => import("../Pages/Coding/POS/Pos"));
const RevenueCode = React.lazy(() =>
  import("../Pages/Institutional/RevenueCode/RevCode")
);
const ValueCode = React.lazy(() =>
  import("../Pages/Institutional/ValueCode/ValueCode")
);
const ConditionCode = React.lazy(() =>
  import("../Pages/Institutional/ConditionCode/CondCode")
);
const OccuranceCode = React.lazy(() =>
  import("../Pages/Institutional/OccuranceCode/OccuCode")
);
const OccuranceSpanCode = React.lazy(() =>
  import("../Pages/Institutional/OccuranceSpanCode/OccSpanCode")
);
const AdjustmentCode = React.lazy(() =>
  import("../Pages/EDICodes/AdjustmentCode/AdjustmentCodes")
);
const RemarkCode = React.lazy(() =>
  import("../Pages/EDICodes/RemarkCode/RemarkCodes")
);

const DBScript = React.lazy(() =>
  import("../Pages/ScriptStore/DBScript/index")
);

const ExternalFiles = React.lazy(() =>
  import("../Pages/DataMigration/ExternalFiles/ExternalFiles")
);
const PatientSheet = React.lazy(() =>
  import("../Pages/DataMigration/PatientSheet/PatSheet")
);
const ChargesSheet = React.lazy(() =>
  import("../Pages/DataMigration/ChargesSheet/ChargSheet")
);
// const InsuranceMapping = React.lazy(() =>
//   import("../Pages/DataMigration/InsuranceMapping/InsuranceMap")
// );
const SchedulerJob = React.lazy(() =>
  import("../Pages/DataMigration/SchedulerJob/SchedulerJob")
);
const MismatchedVisits = React.lazy(() =>
  import("../Pages/DataMigration/MismatchedVisits/MismatcheVisit")
);
const GeneralItems = React.lazy(() =>
  import("../Pages/EHRSetup/GeneralItems/GenItems")
);
const Lab = React.lazy(() => import("../Pages/EHRSetup/Lab/Lab"));
const Action = React.lazy(() => import("../Pages/Setting/Action/Action"));
const InsuranceGroup = React.lazy(() =>
  import("../Pages/Insurance/InsuranceGroup/index")
);
const InsurancePayer = React.lazy(() =>
  import("../Pages/Insurance/InsurancePayer/InsurancePayer")
);
const InsurancePlanEdiSetup = React.lazy(() =>
  import("../Pages/Insurance/InsurancePlanEdiSetup/InsurancePlanEdiSetup")
);

const PlanType = React.lazy(() => import("../Pages/Insurance/PlanType/index"));
const PProvider = React.lazy(() => import("../Pages/Admin/PracticeProvider"));
const RolesAndRights = React.lazy(() =>
  import("../Pages/RolesAndRights/Index")
);

const routes = [
  { path: ROUTES.home, component: Dashboard },
  {
    path: ROUTES.dashboard,
    component: Dashboard,
  },
  { path: ROUTES.database, component: Database },
  { path: ROUTES.client, component: Client },
  { path: ROUTES.user, component: User },
  { path: ROUTES.team, component: Team },
  { path: ROUTES.practice, component: Practice },
  { path: ROUTES.location, component: Location },
  {
    path: ROUTES.practiceProvider,

    component: PProvider,
  },

  {
    path: ROUTES.referringProvider,

    component: ReferingProvider,
  },
  { path: ROUTES.provider, component: Provider },
  {
    path: ROUTES.taxonomyCode,

    component: TaxonomyCode,
  },
  { path: ROUTES.zipCode, component: ZipCode },
  { path: ROUTES.group, component: Group },

  { path: ROUTES.settings, component: Settings },
  { path: ROUTES.externalSetting, component: ExternalSetting },
  { path: ROUTES.login, component: Login },
  {
    path: ROUTES.insurance,

    component: InsuranceGroup,
  },
  {
    path: ROUTES.insurance,

    component: InsurancePlanEdiSetup,
  },
  {
    path: ROUTES.insurance,

    component: InsurancePayer,
  },
  {
    path: ROUTES.providerEnrollment,

    component: ProviderEnrollment,
  },
  {
    path: ROUTES.insurancePlan,
    component: InsurancePlan,
  },
  {
    path: ROUTES.insurancePortal,
    component: InsurancePortal,
  },
  {
    path: ROUTES.insuranceMapping,
    component: insuranceMapping,
  },
  {
    path: ROUTES.insurancePlanAddress,
    component: InsurancePlanAddress,
  },
  {
    path: ROUTES.DBScript,
    component: DBScript,
  },

  {
    path: ROUTES.Receiver,
    component: Receiver,
  },

  {
    path: ROUTES.Submitter,
    component: Submitter,
  },

  { path: ROUTES.icd, component: ICD },
  { path: ROUTES.cpt, component: CPT },
  {
    path: ROUTES.modifiers,
    component: Modifiers,
  },
  { path: ROUTES.pos, component: POS },
  {
    path: ROUTES.revenueCode,
    component: RevenueCode,
  },
  {
    path: ROUTES.valueCode,
    component: ValueCode,
  },
  {
    path: ROUTES.conditionCode,
    component: ConditionCode,
  },
  {
    path: ROUTES.occuranceCode,
    component: OccuranceCode,
  },
  {
    path: ROUTES.occuranceSpanCode,
    component: OccuranceSpanCode,
  },
  {
    path: ROUTES.adjustmentCode,
    component: AdjustmentCode,
  },
  {
    path: ROUTES.remarkCode,
    component: RemarkCode,
  },
  {
    path: ROUTES.adjustmentAction,
    component: AdjustmentAction,
  },
  {
    path: ROUTES.externalFiles,
    component: ExternalFiles,
  },
  {
    path: ROUTES.patientSheet,
    component: PatientSheet,
  },
  {
    path: ROUTES.chargesSheet,
    component: ChargesSheet,
  },
  {
    path: ROUTES.schedulerJob,
    component: SchedulerJob,
  },
  {
    path: ROUTES.mismatchedVisits,
    component: MismatchedVisits,
  },
  {
    path: ROUTES.generalItems,
    component: GeneralItems,
  },
  { path: ROUTES.lab, component: Lab },
  { path: ROUTES.Action, component: Action },
  {
    path: ROUTES.insuranceGroup,
    component: InsuranceGroup,
  },
  {
    path: ROUTES.InsurancePlanEdiSetup,
    component: InsurancePlanEdiSetup,
  },
  {
    path: ROUTES.InsurancePayer,
    component: InsurancePayer,
  },
  {
    path: ROUTES.planType,
    component: PlanType,
  },
  {
    path: ROUTES.RolesAndRights,
    component: RolesAndRights,
  },
];

export default routes;
