import axios from 'axios'
import store from '../Redux/Store'
// import Store from './../Redux/Store'

const PrivateApiCall = (endPoint, method, payload, port) => {
  // let url = process.env.REACT_APP_AuthenticationService
  // if (port === 7002) {
  //     url = process.env.REACT_APP_AuthenticationService
  // }

  let token = store.getState().auth.login
  const authHeaders = token
    ? {
      Authorization: `Bearer ${token}`,
    }
    : {}
  const config = {
    method,
    // url: `${url + endPoint}`,
    url: `${endPoint}`,
    data: payload,
    headers: {
      Accept: '*/*',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Access-Control-Max-Age': '6000',
      'Access-Control-Allow-Headers': '*',
      ...authHeaders,
    },
  }
  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        resolve(res.data.dataModel)
      })
      .catch((error) => {
        if (error && error.response) {






          if (error.response.data && error.response.data.isError) {


            reject({
              message: error.response.data.responseMessage,
              statusCode: error.response.data.statusCode,
            })

          }




          else if (error && error.response) {

            if (error.response.data && error.response.data.errors) {
              console.log(error.response.data, "dosra aya hai sarkar");
              const parsederrors = error.response.data.errors
              console.log(parsederrors, "Hon daso fiir");
              for (const value in parsederrors) {
                parsederrors[value].forEach((errmsg) => {
                  reject({
                    message: errmsg,
                    statusCode: error.response.data.status,
                  })
                })
              }
            }
          }




          else {
            reject({
              message: error.response.status,
              statusCode: error.response.statusText,
            })
          }
        } else {
          reject({
            message: error,
          })
        }
      })
  })
}

export { PrivateApiCall }
