import React, { useState, useEffect } from "react";
import { MyButton } from "../../../Common/MyButton";
import { enumUtil } from "../../../Utils/enumUtil";
import toaster from "../../../Utils/toaster";
import { PrivateApiCall } from "../../../Api/PrivateApiCall";
import isNull from "../../../Utils/NullCheck";
import { ErrorHandlingMessage } from "../../../Utils/error-handling-message";
import Validator from "../../../Utils/Validator";
import PageLoader from "../../../Common/PageLoader";
import { ShortKey } from "../../../Utils/ShortKey";
const groupCodes = [
  { value: "", display: "" },
  { value: "CO", display: "CO" },
  { value: "OA", display: "OA" },
  { value: "PI", display: "PI" },
  { value: "PR", display: "PR" },
];
const status = [
  { value: "", display: "Please Select" },
  { value: "D", display: "Denial" },
  { value: "W", display: "WriteOff" },
  { value: "PR", display: "Patient Responsibility" },
];
function AdjustmentActionAdd(props) {
  let finaly_Validation_Obj = {};
  const REACT_APP_Common = process.env.REACT_APP_Common;
  const save_Modal = {
    addedBy: "",
    addedDate: new Date(),
    updatedBy: "",
    updatedDate: "",
    inActive: false,
    inActiveReason: "",
    inActiveBy: "",
    inActiveDate: "",
    id: 0,
    description: "",
    rejectionCode: "",
    groupCode: "",
    reasonCode: "",
    remarkCode: "",
    status: "",
  };

  const validatorModalVar = {
    groupCode: "",
    reasonCode: "",
    description: "",
    status: "",
  };
  const [validatorModal, setvalidatorModal] = useState(validatorModalVar);
  const [saveModal, setsaveModal] = useState(save_Modal);
  const [isLoading, setIsLoading] = useState(false);
  const [saveClicked, setsaveClicked] = useState(false);
  useEffect(() => {
    if (!isNull(props.editID)) {
      PrivateApiCall(
        `${REACT_APP_Common}/PaymentAdjustmentActionCodes/FindPaymentAdjustmentActionCode/${props.editID}`,
        "get"
      )
        .then((res) => {
          //   console.log(res);
          setsaveModal((prev) => ({
            ...prev,
            ...res,
          }));
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          ErrorHandlingMessage(error);
          // console.log(error)
        });
    }
  }, [props.editID]);

  const setValidation = () => {
    //---------------------object for intial three field validation-------------
    let obj = {
      ...validatorModal,
      groupCode: Validator(
        saveModal.groupCode,
        enumUtil.enumValidationType.Required,
        "Group Code"
      ),
      reasonCode: Validator(
        saveModal.reasonCode,
        enumUtil.enumValidationType.Required,
        "Reason Code"
      ),
      description: Validator(
        saveModal.description,
        enumUtil.enumValidationType.Required,
        "Description"
      ),
      status: Validator(
        saveModal.status,
        enumUtil.enumValidationType.Required,
        "Status"
      ),
    };

    finaly_Validation_Obj = obj;
    setvalidatorModal(obj);
  };
  const posHandler = (e) => {
    // setValidation();

    let value = e.target.value;
    let name = e.target.name;
    setsaveModal((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const savePos = () => {
    setValidation();
    let validation = Validator(
      [finaly_Validation_Obj],
      enumUtil.enumValidationType.NullCheck
    );
    if (validation) {
      setsaveClicked(true);
      toaster("Please Fill The Required Fields", enumUtil.enumtoaster.error);
      return;
    } else {
      setsaveClicked(false);
    }
    setIsLoading(true);
    PrivateApiCall(
      `${REACT_APP_Common}/PaymentAdjustmentActionCodes/Save`,
      "post",
      saveModal
    )
      .then((res) => {
        setIsLoading(false);
        props.findPos();
        setsaveModal(save_Modal);
        toaster("Save Successfully", enumUtil.enumtoaster.success);
        props.toggleAddNewScreen();
      })
      .catch((error) => {
        setIsLoading(false);
        props.toggleAddNewScreen();
        ErrorHandlingMessage(error);
        // console.log(error)
      });
  };
  const logKey = (e) => {
    if (ShortKey(e, e.keyCode) === "save") {
      savePos();
    }
    if (ShortKey(e, e.keyCode) === "close") {
      props.toggleAddNewScreen();
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", logKey);
    return () => {
      window.removeEventListener("keydown", logKey);
    };
  }, [props.toggleAddNewScreen]);
  return (
    <>
      {isLoading ? <PageLoader /> : null}
      <div
        className="modal fade show d-block popBackground "
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div
            style={{
              position: "relative",
            }}
            className="modal-content "
          >
            <div className="modal-header ">
              <div
                style={{
                  lineHeight: "0.2",
                }}
              >
                <h5 className="modal-header-tittle">New Adjustment Action</h5>
              </div>
              <div>
                <span
                  onClick={() => {
                    props.toggleAddNewScreen();
                  }}
                  data-dismiss="modal"
                  aria-label="Close"
                  className="modalCloseButtonHeader"
                >
                  x
                </span>
              </div>
            </div>
            <div className="modal-body">
              <div className="body">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label htmlFor="groupCode" className="required">
                        Group Code
                      </label>
                      <select
                        className="form-control"
                        name="groupCode"
                        id="groupCode"
                        value={saveModal?.groupCode}
                        onChange={posHandler}
                      >
                        {/* <option value={null}>Please Select</option> */}
                        {groupCodes.map((el) => {
                          return (
                            <option key={el.value} value={el.value}>
                              {el.display}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {saveClicked === true ? validatorModal.groupCode : ""}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="required" htmlFor="name">
                        Reason Code
                      </label>
                      <input
                        className="form-control"
                        maxlength="5"
                        type="text"
                        name="reasonCode"
                        onChange={posHandler}
                        value={saveModal?.reasonCode}
                      />
                      {saveClicked === true ? validatorModal.reasonCode : ""}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="" htmlFor="remarkCode">
                        Remark Code
                      </label>
                      <input
                        className="form-control"
                        maxlength="5"
                        type="text"
                        name="remarkCode"
                        onChange={posHandler}
                        value={saveModal?.remarkCode}
                      />
                      {/* {saveClicked === true ? validatorModal.RemarkCode : ''} */}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label htmlFor="description" className="required">
                        Description
                      </label>
                      <input
                        rows="3"
                        id="description"
                        onChange={posHandler}
                        value={saveModal?.description}
                        name="description"
                        className="form-control no-resize"
                        // placeholder="Please type your notes..."
                      ></input>
                    </div>
                    {saveClicked === true ? validatorModal.description : ""}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label htmlFor="groupCode" className="required">
                        Status
                      </label>
                      <select
                        className="form-control"
                        name="status"
                        id="status"
                        value={saveModal?.status}
                        onChange={posHandler}
                      >
                        {status.map((status, index) => (
                          <option value={status.value} key={index}>
                            {status.display}
                          </option>
                        ))}
                      </select>
                    </div>
                    {saveClicked === true ? validatorModal.status : ""}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <MyButton
                  buttonId="closeDBBtn"
                  buttonLabel="Close"
                  // className="btn - btn-danger center p-l-2 modal-addButtonCustom  mr-2"
                  buttonClasses="btn - btn-danger center p-l-2 modal-addButtonCustom  mr-2"
                  buttonOnchange={props.toggleAddNewScreen}
                  // buttonDisabled={isLoading}
                />
                <MyButton
                  // disabled={saveReferralCount === 0 ? false : true}
                  buttonId="saveDBBtn"
                  buttonLabel="Save"
                  buttonClasses="btn - btn-success center p-l-2 modal-addButtonCustom  mr-2"
                  buttonOnchange={savePos}
                  // disabledAndLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdjustmentActionAdd;
