import React from "react";
import isNull from "../../Utils/NullCheck"; // Import isNull from Utilities-isNull
//Styles
import "./myautocomplete.css";
import Store from "../../Redux/Store";

const loaderIcon = (
  <div className="lds-spinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);
class MyAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    //Base urls
    this.visitUrl = process.env.REACT_APP_URL + "/visit/";
    this.keyCode = null;

    this.state = {
      token:
        (this.props.token || (Store.getState().auth.login && Store.getState().auth.login)),
      searchValue: "",
      currentFocus: -1,
      selectedID: null,
      // isFound: false,
      list: this.props.options ? this.props.options : [],
      newList: this.props.options ? this.props.options : [],
      isEntered: false,
      isLoading: false,
      isFound: true,
      isOpen: false,
      isOptionSelected: false,
    };
    //Authorization Token
    this.config = {
      headers: {
        Authorization: "Bearer  " + this.state.token,
        Accept: "*/*",
      },
    };
  }

  outClickRef = React.createRef();
  componentWillReceiveProps(props) {
    if (this.props.baseurl) {
      this.setState({
        searchValue: props.value,
      });
    } else {
      this.setState({
        searchValue: props.value,
        newList: props.options ? props.options : [],
        list: props.options ? props.options : [],
      });
    }
  }
  componentDidUpdate(props) {
    if (props.value !== this.state.searchValue) {
      this.setState({
        searchValue: props.value,
        token: props.token || this.state.token,
      });
    }
  }
  onFocus = async (e) => {
    let json;
    if (this.props.baseurl) {
      const response = await fetch(this.props.baseurl + this.props.api, {
        ...this.config,
        headers: {
          ...this.config.headers,
          Authorization: "Bearer  " + (this.props.token || this.state.token),
        },
      });
      json = await response.json();
      json = json.dataModel;
      this.setState({
        ...this.state,
        newList: json,
        list: json,
        // isOpen: true,
        // isFound: true,
        currentFocus: 0,
        // searchValue: "",
      });
      this.createList("", true);
    } else {
      json = this.state.list;
    }
  };
  handleClickOutside = (e) => {
    if (!this.outClickRef.current?.contains(e.target)) {
      let foundList = document.getElementById(
        "myAutocomplete-list-" + this.props.id
      );
      if (foundList) {
        document
          .getElementById("myAutocompleteParentDiv" + this.props.id)
          .removeChild(foundList);
      }
      this.setState({
        searchValue: "",
        currentFocus: -1,
        selectedID: null,
        isFound: false,
        list: [],
        newList: [],
        isEntered: false,
        isLoading: false,

        isOpen: false,
        isOptionSelected: false,
      });
    }
    // console.log(this.outClickRef);
  };
  componentDidMount() {
    this.setState({ searchValue: this.props.value });
    /*function CLOSES THE LIST when someone clicks in the document:*/
    document.addEventListener("click", this.handleClickOutside);

    document.addEventListener("click", function (elmnt, e) {
      // document.body.style.overflow = "visible";
      //  var x = document.getElementsByClassName("myAutocomplete-items");
      // for (var i = 0; i < x.length; i++) {
      //   if (elmnt != x[i] && elmnt != e) {
      //     x[i].parentNode.removeChild(x[i]);
      //   }
      // }

      //Enable Scroll
      try {
        let a = document.getElementsByClassName("serviceline")[0];
        let b = a.childNodes[1];
        let c = b.childNodes[0];
        let d = c.childNodes[0];
        d.style.overflowX = "";
      } catch (error) { }
    });
  }
  componentWillUnmount() {
    // Removing of event listener
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  filterOption = async (list, eventValue) => {
    try {
      let newList = [];
      for (var i = 0; i < list.length; i++) {
        if (!isNull(list[i].label)) {
          if (this.props.searchFilter) {
            if (this.props.searchFilter === "startsWith") {
              if (list[i].label.startsWith(eventValue.toUpperCase())) {
                newList = [...newList, list[i]];
              }
            }
          } else {
            let refinementLabel = list[i].label.toLowerCase();

            if (refinementLabel.includes(eventValue.toLowerCase())) {
              newList = [...newList, list[i]];

              //if (list[i].label.includes(eventValue.toUpperCase())) {
              //newList = [...newList, list[i]]
            }
          }
        }
      }
      await this.setState({
        list,
        newList,
        // newList: newList,
        isLoading: false,
      });
      this.createList(eventValue);
    } catch { }
  };

  /*execute a function when someone writes in the text field:*/
  addEventListener = async (e) => {
    this.keyCode = null;
    // let eventID = e.target.id;
    let eventValue = e.target.value;
    if (this.props.onChange)
      this.props.onChange(eventValue ? eventValue.toUpperCase() : eventValue);
    //set search value in state
    await this.setState({
      searchValue: eventValue,
      isOptionSelected: false,
      currentFocus: -1,
      isEntered: true,
      isFound: true,
    });
    /*close any already open lists of autocompleted values*/
    await this.closeAllLists(e);
    // Return if event value is empty
    if (!eventValue) {
      await this.closeAllLists();
      return false;
    }

    await this.fetchData(eventValue, this.filterOption);
  };

  //fetch API DATA
  fetchData = async (eventValue, CallBack) => {
    try {
      if (eventValue.length < 2) {
        this.setState({ isLoading: false });
        return;
      }
      //this.setState({ isLoading: true ,newList:[]})

      if (this.state.newList.length !== 0) {
        await this.filterOption(this.state.list, eventValue);
      }
      if (this.state.newList.length === 0) {
        let json;
        if (this.props.baseurl) {
          const response = await fetch(this.props.baseurl + this.props.api, {
            ...this.config,
            headers: {
              ...this.config.headers,
              Authorization:
                "Bearer  " + (this.props.token || this.state.token),
            },
          });
          json = await response.json();
          json = json.dataModel;
        } else {
          json = this.state.list;
        }
        this.closeAllLists();
        if (!this.state.searchValue) {
          return;
        }
        if (json.length === 0) {
          await this.setState({
            isFound: false,
          });
        }

        CallBack(json, eventValue);
      } else {
        this.closeAllLists();
        if (!this.state.searchValue) {
          return;
        }

        CallBack(this.state.list, eventValue);
      }
    } catch {
      this.setState({ isLoading: false });
    }
  };

  createList = async (eventValue, onFocus) => {
    let listOptionsDiv, option
    /*create listOptionsDiv DIV element that will contain the items (values):*/
    try {
      listOptionsDiv = document.createElement("DIV");
      listOptionsDiv.setAttribute("id", "myAutocomplete-list-" + this.props.id);
      listOptionsDiv.setAttribute("class", "myAutocomplete-items");
      listOptionsDiv.style.maxHeight = "190px";
      listOptionsDiv.style.overflowY = "auto";
      listOptionsDiv.style.backgroundColor = "white";
      listOptionsDiv.style.width = this.props.menuWidth
        ? this.props.menuWidth
        : "100%";
      /*append the DIV element as a child of the autocomplete container:*/
      let oldList = document.getElementById(
        "myAutocomplete-list-" + this.props.id
      );
      if (oldList) {
        document
          .getElementById("myAutocompleteParentDiv" + this.props.id)
          .removeChild(oldList);
      }
      document
        .getElementById("myAutocompleteParentDiv" + this.props.id)
        .appendChild(listOptionsDiv);

      /*for each item in the array...*/
      await this.state.newList.forEach((row, index) => {
        try {
          let a = document.getElementsByClassName("serviceline")[0];
          let b = a.childNodes[1];
          let c = b.childNodes[0];
          let d = c.childNodes[0];
          d.style.overflowX = "inherit";
        } catch (error) { }

        var start = onFocus
          ? 0
          : row.label.toUpperCase().indexOf(eventValue.toUpperCase());
        if (start > -1) {
          /*create a DIV element for each matching element:*/
          option = document.createElement("DIV");
          option.setAttribute("title", "searchValue");
          option.setAttribute("id", row.patientID);
          option.setAttribute("class", "colorBlack"); // inst visit
          option.onclick = (event) => this.optionSelected(event, index);
          option.innerHTML = row.label.substr(0, start);
          if (this.props.id === "patientSerach") {
            if (row.isActive === false) {
              option.style.backgroundColor = "#FAD2DA";
            }
          }
          /*make the matching letters bold:*/
          option.innerHTML +=
            "<strong>" +
            row.label.substr(start, eventValue.length) +
            "</strong>";
          option.innerHTML += row.label.substr(start + eventValue.length);
          /*insert a input field that will hold the current array item's value:*/
          option.innerHTML += "<input type='hidden' value='" + row.label + "'>";
          /*execute a function when someone clicks on the item value (DIV element):*/
          listOptionsDiv.appendChild(option);
        }
      });

      var x = document.getElementById("myAutocomplete-list-" + this.props.id);
      if (x) x = x.getElementsByTagName("div");
      this.addActive(x, 0);
    } catch (error) {
      console.log(error);
    }
  };

  optionSelected = async (e, index) => {
    e.preventDefault();
    this.keyCode = 9;
    this.closeAllLists(e);
    if (e) {
      let data = [this.state.newList[index]];
      await this.setState({
        searchValue: e.target.innerText,
        isOptionSelected: true,
      });

      if (data.length > 0) {
        this.props.onOptoinSelected(data[0]);
      }
    }
  };

  KeyDown = async (e) => {
    var x = document.getElementById("myAutocomplete-list-" + this.props.id);
    if (x) x = x.getElementsByTagName("div");
    if (e.keyCode === 40) {
      /*and and make the current item more visible:*/
      this.addActive(x, this.state.currentFocus + 1);
      /*If the arrow DOWN key is pressed, increase the currentFocus variable:*/
      await this.setState({ currentFocus: this.state.currentFocus + 1 });
    } else if (e.keyCode === 38) {
      /*and and make the current item more visible:*/
      this.addActive(x, this.state.currentFocus - 1);
      await this.setState({ currentFocus: this.state.currentFocus - 1 });
    } else if (e.keyCode === 13) {
      /*If the ENTER key is pressed, prevent the form from being submitted,*/
      e.preventDefault();
      if (this.state.currentFocus > -1) {
        /*and simulate a click on the "active" item:*/
        if (x) x[this.state.currentFocus].click();
      } else {
        if (this.state.newList.length > 0) {
          this.keyCode = 9;
          await this.setState({ isOptionSelected: true });
          this.props.onOptoinSelected(this.state.newList[0]);
          this.closeAllLists();
        }
      }
    } else if (e.keyCode === 9) {
      this.keyCode = 9;
      if (this.state.isOptionSelected === false) {
        this.closeAllLists();
        this.props.onOptoinSelected(null);
      }
    } else if (e.ctrlKey && e.keyCode === 32) {
      this.keyCode = "32";
      this.createList("");
    } else {
      this.keyCode = null;
    }
  };

  addActive = async (x, currentFocus) => {
    var crrFocus = currentFocus;
    /*a function to classify an item as "active":*/
    try {
      if (!x) return false;
      /*start by removing the "active" class on all items:*/
      await this.removeActive(x);

      if (crrFocus >= x.length) {
        crrFocus = 0;
      }
      if (crrFocus < 0) {
        crrFocus = x.length - 1;
      }

      /*add class "myAutocomplete-active":*/

      if (this.props.id === "patientSerach") {
        var active = document.getElementById(
          "myAutocomplete-list-" + this.props.id
        );
        if (active) active = active.getElementsByTagName("div");
        if (
          active &&
          active[0].style.backgroundColor === "rgb(250, 210, 218)"
        ) {
        } else {
          x[crrFocus].classList.add("myAutocomplete-active");
        }
      } else {
        x[crrFocus].classList.add("myAutocomplete-active");
      }

      await this.setState({ currentFocus: crrFocus });
    } catch { }
  };

  removeActive = (x) => {
    /*a function to remove the "active" class from all autocomplete items:*/
    for (var i = 0; i < x.length; i++) {
      x[i].classList.remove("myAutocomplete-active");
    }
  };

  closeAllLists = (elmnt, e) => {
    // document.body.style.overflow = "visible";
    var x = document.getElementsByClassName("myAutocomplete-items");
    for (var i = 0; i < x.length; i++) {
      x[i].parentNode.removeChild(x[i]);
    }

    try {
      let a = document.getElementsByClassName("serviceline")[0];
      let b = a.childNodes[1];
      let c = b.childNodes[0];
      let d = c.childNodes[0];
      d.style.overflowX = "";
    } catch (error) { }
  };

  clearField = (e) => {
    this.setState({ searchValue: "", isOptionSelected: false });
    this.closeAllLists();
    this.props.onOptionCancel(null);
  };

  isNull = (value) => {
    if (
      value === "" ||
      value === null ||
      value === undefined ||
      value === "Please Select" ||
      value === 0 ||
      value === -1
    )
      return true;
    else return false;
  };

  onBlurFunction = (event) => {
    setTimeout(() => {
      if (this.keyCode === null && this.state.isEntered === true) {
        this.closeAllLists();
        this.props.onOptoinSelected(null);
      } else if (this.keyCode === "32") {
        this.closeAllLists();
      }
    }, 1000 / 2);
  };

  handleClicking = (event) => {
    event.preventDefault();
    if (this.props.searchIconClick) {
      this.props.searchIconClick(event);
    }
  };

  render() {
    // console.log(Store.getState())
    //Main Div Styles
    let marginLeft = "0px";
    let position = "absolute";
    let width = "100%";
    if (this.props.style) {
      if (this.props.style.marginLeft) {
        marginLeft = this.props.style.marginLeft;
      }
      if (this.props.style.position) {
        position = this.props.style.position;
      }
      if (this.props.style.width) {
        width = this.props.style.width;
      }
    }

    //Cross Icon Styles
    let crossIconPosition = "absolute";
    let crossIconTop = "4px";
    let crossIconRight = "5px";
    if (this.props.crossIconStyles) {
      crossIconPosition = this.props.crossIconStyles.position
        ? this.props.crossIconStyles.position
        : crossIconPosition;
      crossIconTop = this.props.crossIconStyles.top
        ? this.props.crossIconStyles.top
        : crossIconTop;
      crossIconRight = this.props.crossIconStyles.right
        ? this.props.crossIconStyles.right
        : crossIconRight;
    }

    // Search Icon Styles
    let searchIconPosition = "absolute";
    let searchIconTop = "8px";
    let searchIconRight = "5px";
    if (this.props.searchIconStyles) {
      searchIconPosition = this.props.searchIconStyles.position
        ? this.props.searchIconStyles.position
        : searchIconPosition;
      searchIconTop = this.props.searchIconStyles.top
        ? this.props.searchIconStyles.top
        : searchIconTop;
      searchIconRight = this.props.searchIconStyles.right
        ? this.props.searchIconStyles.right
        : searchIconRight;
    }

    //Loading Icon Styles
    let loadingIconPosition = "absolute";
    let loadingIconTop = "0px";
    let loadingIconRight = "3px";
    if (this.props.loadingIconStyles) {
      loadingIconPosition = this.props.loadingIconStyles.position
        ? this.props.loadingIconStyles.position
        : loadingIconPosition;
      loadingIconTop = this.props.loadingIconStyles.top
        ? this.props.loadingIconStyles.top
        : loadingIconTop;
      loadingIconRight = this.props.loadingIconStyles.right
        ? this.props.loadingIconStyles.right
        : loadingIconRight;
    }
    return (
      <div
        ref={this.outClickRef}
        style={{
          position: this.props.parentPosition,
          width: this.props.parentWidth ? this.props.parentWidth : "100%",
        }}
      >
        <div
          id={"myAutocompleteParentDiv" + this.props.id}
          style={{
            width,
            position,
          }}
        >
          <input
            disabled={this.props.isDisabled ? true : false}
            className="provider-form"
            autocomplete="off"
            onFocus={this.props.onFocus ? this.onFocus : () => { }}
            value={this.state.searchValue ? this.state.searchValue : ""}
            onChange={(e) => this.addEventListener(e)}
            onKeyDown={(e) => this.KeyDown(e)}
            onBlur={(event) => this.onBlurFunction(event)}
            onDoubleClick={
              this.props.onDoubleClick
                ? (event) => this.props.onDoubleClick(event)
                : undefined
            }
            id="myAutocompleteID"
            type="text"
            name="searchValue"
            placeholder={this.props.placeholder ? this.props.placeholder : ""}
            style={{ marginLeft }}
          />

          {this.isNull(this.state.searchValue) ? (
            <span
              style={{
                position: searchIconPosition,
                right: searchIconRight,
                top: searchIconTop,
              }}
            >
              <i
                className="my-autocomplete-search-icon"
                onClick={(e) => this.handleClicking(e)}
              ></i>
            </span>
          ) : this.state.isLoading ? (
            <span
              style={{
                position: loadingIconPosition,
                right: loadingIconRight,
                top: loadingIconTop,
              }}
            >
              {loaderIcon}
            </span>
          ) : (
            <span
              style={{
                position: crossIconPosition,
                right: crossIconRight,
                top: crossIconTop,
                display: this.props.isDisabled ? "none" : "unset",
              }}
            >
              <i
                onClick={(e) => this.clearField(e)}
                id="my-autocomplete-cross-icon"
                className="fa fa-times my-autocomplete-cross-icon text-danger pt-1"
              ></i>
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default MyAutocomplete;
