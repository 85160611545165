import moment from 'moment'

const FromToTimeCompare = (fromtime, totime, type) => {

  let fromTime = moment(fromtime, ['h:mm A']).format('HH:mm')
  let toTime = moment(totime, ['h:mm A']).format('HH:mm')
  // if type is equal
  if (type === 'equal') {
    if (fromTime === toTime) {
      return true
    } else {
      return false
    }
  }

  // if from time is greater then to time
  if (type === 'greater') {
    if (fromTime > toTime) {
      return true
    } else {
      return false
    }
  }

  // if from time is greater then or equal to 'to time'
  if (type === 'greater_or_equal') {
    if (fromTime >=toTime) {
      return true
    } else {
      return false
    }
  }
}

export default FromToTimeCompare
