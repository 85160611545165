import Swal from 'sweetalert2'
const SwalAlert = (title, text, type, icon) => {

    return Swal.fire({
        position: 'top-end',
        icon: icon,
        title,
        text,
        type,
        showConfirmButton: false,
        timer: 1500
    })
    // Swal.fire(title, text, type)
    // Swal.fire({
    //     position: 'top-end',
    //     icon: 'success',
    //     title: 'Your work has been saved',
    //     showConfirmButton: false,
    //     timer: 1500
    //   })
}

const SwalConfirm = (title, text, type, actionButton, cancelButton) => {
    return new Promise((resolve, reject) => {
        Swal.fire({
            title,
            text,
            icon:type,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: cancelButton ? cancelButton : "Cancel",
            confirmButtonText: actionButton ? actionButton : "OK",
        }).then((result) => {
            if (result) {
                if (result.isConfirmed) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            } else {
                reject()
            }
        });
    })
}

export { SwalAlert, SwalConfirm }