const ShortKey = (e, key) => {
  // alt + n  for new popUp
  if (e.altKey === true && key === 78) {
    return 'add'
  }
  //alt + s for save Data
  if (e.altKey === true && key === 83) {
    return 'save'
  }
  //alt + c for close
  if (e.altKey === true && key === 67) {
    return 'close'
  }
  // escape for close
  if (e.altKey === false && key === 27) {
    return 'esc'
  }
  // alt + f for search data
  if (e.altKey === true && key === 70) {
    return 'find'
  }
}
export { ShortKey }
