export const ROUTES = {
  login: "/",
  home: "/",
  dashboard: "/dashboard",
  settings: "/settings",
  database: "/database",
  client: "/client",

  user: "/user",
  team: "/team",
  practice: "/practice",
  location: "/location",
  practiceProvider: "/practiceProvider",
  provider: "/provider",
  referringProvider: "/referringProvider",
  externalSetting: "/externalSetting",
  setting: "/setting",
  taxonomyCode: "/taxonomycode",
  zipCode: "/zipcode",
  group: "/group",
  insurance: "/insurance",
  insurancePlan: "/insurancePlan",
  insurancePortal: "/insurancePortal",
  insurancePlanAddress: "/insurancePlanAddress",
  ediSubmitPayer: "/ediSubmitPayer",
  ediEligibilityPayer: "/ediEligibilityPayer",
  ediStatusPayer: "/ediStatusPayer",
  ediPlanSetup: "/ediPlanSetup",
  providerEnrollment: "/providerEnrollment",
  icd: "/icd",
  feeSchedule: "/feeSchedule",
  cpt: "/cpt",
  modifiers: "/modifiers",
  pos: "/pos",
  revenueCode: "/revenueCode",
  valueCode: "/valueCode",
  conditionCode: "/conditionCode",
  occuranceCode: "/occuranceCode",
  occuranceSpanCode: "/occuranceSpanCode",
  adjustmentCode: "/adjustmentCode",
  remarkCode: "/remarkCode",
  adjustmentAction: "/adjustmentAction",
  receiver: "/receiver",
  submitter: "/submitter",
  externalFiles: "/externalFiles",
  patientSheet: "/patientSheet",
  chargesSheet: "/chargesSheet",
  insuranceMapping: "/insuranceMapping",
  schedulerJob: "/schedulerJob",
  mismatchedVisits: "mismatchedVisits",
  generalItems: "/generalItems",
  lab: "/lab",
  Action: "/action",
  insuranceGroup: "/insuranceGroup",
  InsurancePlanEdiSetup: "/InsurancePlanEdiSetup",
  InsurancePayer: "/InsurancePayer",
  planType: "/planType",
  DBScript: "/DBScript",
  Receiver: "/Receiver",
  Submitter: "/Submitter",
  RolesAndRights: "/RolesAndRights",
};
