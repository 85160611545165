/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useEffect, useLayoutEffect, useState } from "react";
import userImg from "../../../assets/images/user.png";
import logo from "../../../assets/images/logo.png";
import logocollapsed from "../../../assets/images/favicon.png";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "../../../Routes/RoutesPath";
import { SideMenu } from "../SideMenu";
import { useSelector } from "react-redux";
import {MdSpaceDashboard} from "react-icons/md";

export default function SidenavBar({
  toggleSidebar,
  toggleSideNavBar,
  toggleSideNavBaronLargeScreen,
}) {
  const [userMenu, setUserMenu] = useState(false);
  // const [clientSetupMenu, setClientSetupMenu] = useState(false);
  // const [moreCollapse, setMoreCollapse] = useState(false);
  const [pathName, setPathName] = useState("");
  const [sideMenuArray, setSideMenuArray] = useState([]);
  const [activeMenu, setActiveMenu] = useState(false);
  const [Windowwidth, handlewidth] = useState({ width: window.innerWidth });
  const location = useLocation();

  let SideMenuArray = [...SideMenu];
  let pathNameVar = location.pathname;
  const userName = useSelector(
    (state) => state.auth.userInfo.loginUserInfo.userName
  );

  useLayoutEffect(() => {
    setSideMenuArray([...SideMenuArray]);
  }, []);
  useEffect(() => {
    reportWindowSize();
    window.addEventListener("resize", reportWindowSize);
    return () => {
      window.removeEventListener("resize", reportWindowSize);
    };
  }, [Windowwidth.width]);
  useEffect(() => {
    if (pathNameVar.length > 1 && pathName !== pathNameVar)
      setPathName(pathNameVar);
  }, [pathNameVar]);

  const HandleClickToogle = (key) => {
    if (key) {
      let tempArr = [...sideMenuArray].map((el) =>
        el.key === key
          ? { ...el, isSubMenuOpen: true }
          : { ...el, isSubMenuOpen: false }
      );
      setSideMenuArray(tempArr);
      if (toggleSideNavBaronLargeScreen === false) {
        setActiveMenu(
          tempArr.some((value) => value.key === key && value.isSubMenuOpen)
        );
      }
    } else {
      setActiveMenu(false);
    }
  };

  const openUserMenu = (e) => {
    e.preventDefault();
    setUserMenu(!userMenu);
  };
  function reportWindowSize() {
    handlewidth({ width: window.innerWidth });
    var width = Windowwidth.width;
    if (width >= 1024) {
      var id = window.document.getElementById("left-sidebar");
      // var mainPageSmallToggleId = window.document.getElementById(
      //   "mainPageSmallToggleId"
      // );
      id.style.removeProperty("left");
      id.style.removeProperty("display");
    } else {
      toggleSideNavBar(false);
    }
  }
  const activeMenuClass = (param) => {
    if (param === ROUTES.practice || param === ROUTES.settings) {
      setPathName(param);
    } else {
      setPathName(param);
      // setMoreCollapse(false);
      // setClientSetupMenu(false);
    }
  };

  // const toggleClientSetupMenu = () => {
  //   setPathName("");
  //   setClientSetupMenu(!clientSetupMenu);
  //   setMoreCollapse(false);
  // };

  // const toggleMoreMenu = () => {
  //   setPathName("");
  //   setClientSetupMenu(false);
  //   setMoreCollapse(!moreCollapse);
  // };

  const openSubmenu = (key) => {
    let tempArr = [...sideMenuArray];
    tempArr.forEach((el) => {
      if (el.key === key) {
        el.isSubMenuOpen = true;
        el.active = true;
      } else {
        el.isSubMenuOpen = false;
        el.active = false;
      }
    });
    setSideMenuArray(tempArr);
  };
  return (
    <div
      id="left-sidebar"
      className="sidebar"
      style={{ left: toggleSidebar ? "-0px" : "-200px" }}
    >
      <div className="navbar-brand">
        <a href="index.html">
          <img
            src={toggleSideNavBaronLargeScreen ? logo : logocollapsed}
            alt="True Care Logo"
            className="img-fluid logo"
          />
        </a>
        <button
          type="button"
          className={`btn-toggle-offcanvas btn btn-sm btn-default float-right`}
          // style={{ display: toggleSidebar ? "block" : "none" }}
          id="mainPageSmallToggleId"
          onClick={toggleSideNavBar}
        >
          <i className="lnr lnr-menu fa fa-chevron-circle-left" />
        </button>
      </div>
      <div className="sidebar-scroll">
        <div className="user-account">
          {/* <div className="user_div">
            <img src={userImg} className="user-photo" alt="User Profile " />
          </div> */}
          <div className={userMenu ? "dropdown show" : "dropdown"}>
            {/* <span>Welcome,</span> */}
            <a
              onClick={openUserMenu}
              href="$/"
              className="dropdown-toggle user-name"
              data-toggle="dropdown"
            >
              <strong>{userName}</strong>
            </a>
            <ul
              className={
                userMenu
                  ? "dropdown-menu dropdown-menu-right account show"
                  : "dropdown-menu dropdown-menu-right account"
              }
            >
              {/* <li
                                onClick={() => activeMenuClass(ROUTES.profile)}>
                                <Link
                                    className="has-arrow"
                                    to={ROUTES.profile}>
                                    <i className="icon-user" />My Profile
                                </Link>
                            </li>
                            <li
                                onClick={() => activeMenuClass(ROUTES.chat)}>
                                <Link
                                    className="has-arrow"
                                    to={ROUTES.chat}>
                                    <i className="icon-envelope-open" />Messages
                                </Link>
                            </li> */}
              <li onClick={() => activeMenuClass(ROUTES.settings)}>
                <Link className="has-arrow" to={ROUTES.settings}>
                  <i className="icon-settings" />
                  Settings
                </Link>
              </li>
              <li className="divider" />
              <li>
                <a href="/">
                  <i className="icon-power" />
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
        <nav id="left-sidebar-nav" className="sidebar-nav">
          <ul id="main-menu" className="metismenu">
            {sideMenuArray &&
              sideMenuArray.map((menu, key) => {
                return (
                  <li
                    key={key}
                    onClick={() => openSubmenu(menu.key)}
                    className={menu.active ? "active" : ""}
                  >
                    <Link to={menu.path}>
                      {menu.image ? (
                        <menu.image
                         // src={menu.image}
                          onMouseEnter={
                            toggleSideNavBaronLargeScreen
                              ? () => {}
                              : () => HandleClickToogle(menu.key)
                          }
                         // alt="menuIcon"
                          style={{ marginRight: "10px"  }}

                          size={18}
                          className="giveclass"
                        />



                      ) : (
                        <i className={menu.image} />
                      )}
                      <span>{menu.label}</span>
                    </Link>
                    {menu.SubMenu && menu.SubMenu.length > 0 ? (
                      <ul
                        onMouseLeave={
                          toggleSideNavBaronLargeScreen === true
                            ? undefined
                            : () => HandleClickToogle()
                        }
                        className={
                          menu.isSubMenuOpen ? "collapse in" : "collapse"
                        }
                        aria-expanded={
                          menu.isSubMenuOpen && activeMenu ? "true" : "false"
                        }
                      >
                        {menu.SubMenu.map((subMenu, k) => {
                          return (
                            <li
                              className={
                                subMenu.path === pathName ? "active" : ""
                              }
                            >
                              <Link to={subMenu.path}>
                                {subMenu.image ? (
                                  <img
                                    src={subMenu.image}
                                    alt="subMenuIcon"
                                    style={{ height: "20px" }}
                                  />
                                ) : null}{" "}
                                <span>{subMenu.label}</span>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    ) : undefined}
                  </li>
                );
              })}
          </ul>
        </nav>
      </div>
    </div>
  );
}
