import toaster from './toaster'
import { enumUtil } from './enumUtil'



export const ErrorHandlingMessage = (error) => {
  if (error.statusCode) {
    if (error.statusCode === 401) {
      toaster('Unauthorized Access', enumUtil.enumtoaster.error)
      return
    } else if (error.statusCode === 404) {
      toaster(
        'Not Found! Failed With Status Code 404',
        enumUtil.enumtoaster.error,
      )
      return
    } else if (error.statusCode === 400) {
      toaster(`Error:${error.message}`, enumUtil.enumtoaster.error)
      return
    } else {
      toaster('Something went Wrong', enumUtil.enumtoaster.error)
      return
    }
  }
}
