import { useDrag, useDrop } from "react-dnd";
import {HeaderRenderer} from "react-data-grid";
const DraggableHeaderRenderer = ({
  onColumnsReorder,
  column,
  // sortDirection,
  // sortColumnName,
  // onSort,
  ...props
}) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: "COLUMN_DRAG", key: column.key },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [{ isOver }, drop] = useDrop({
    accept: "COLUMN_DRAG",
    drop(key) {
      onColumnsReorder(key, column.key);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  return (
    <div
      ref={(ref) => {
        drag(ref);
        drop(ref);
      }}
      style={{
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: isOver ? "#ececec" : undefined,
        cursor: "move",
      }}
    >
      {HeaderRenderer({ column, ...props })}
    </div>

    // <div
    //   ref={(ref) => {
    //     drag(ref)
    //     drop(ref)
    //   }}
    //   style={{
    //     opacity: isDragging ? 0.5 : 1,
    //     backgroundColor: isOver ? '#ececec' : undefined,
    //     //  cursor: 'move',
    //     cursor: 'pointer',
    //     pointerEvents:column.sortable?'':'none'
    //   }}
    //   onClick={() =>
    //     onSort(
    //       sortDirection === 'NONE'
    //         ? [{ columnKey: column.key, direction: 'ASC' }]
    //         : sortDirection === 'ASC'
    //         ? [{ columnKey: column.key, direction: 'DESC' }]
    //         : [],
    //     )
    //   }
    // >
    //   <span style={{ cursor: 'pointer' }}>
    //     <span>{column.name}</span>
    //     {column.sortable && sortColumnName === column.key ? (
    //       <span className=" float-right" style={{ fontSize: '20px' }}>
    //         {sortDirection === 'ASC' ? (
    //           <i class="fas fa-caret-up" />
    //         ) : sortDirection === 'DESC' ? (
    //           <i class="fas fa-caret-down" />
    //         ) : null}
    //       </span>
    //     ) : null}
    //   </span>

    //     {/* <HeaderRenderer  column={column} sortDirection priority={''} onSort={onSort} isCellSelected={false}/>  */}
    // </div>
  );
};
export default DraggableHeaderRenderer;
