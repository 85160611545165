import React from 'react'
import './PageLoader.css'
export default function PageLoader() {
    return (
        <div className="loaderbgpopup">
            <div className="loader"></div>
        </div>

      


    )
}
