import React, { useState } from "react";
import AddNewProviderEnrollment from "./newProviderEnrollmentSetup";
import ViewProviderEnrollment from "./viewProviderEnrollmentSetup";

const ProviderEnrollment = () => {
  const [
    isNewProviderEnrollmentScreenOpen,
    setisNewProviderEnrollmentScreenOpen,
  ] = useState(false);
  const [rowData, setRowData] = useState();
  const [editFlag, setEditFlag] = useState(false);
  const [gridRefresherFlag, setGridRefresherFlag] = useState(false);

  //*****************For New Pop up Screen**************/
  const addNewProviderEnrollmentPopup = (data) => {
    editFormData(false, null, null);
    setisNewProviderEnrollmentScreenOpen(data);
  };
  //*****************For close Pop up Screen************* */
  const closeNewProviderEnrollmentPopup = () => {
    setisNewProviderEnrollmentScreenOpen(false);
    setGridRefresherFlag(!gridRefresherFlag);
  };
  const editFormData = (status, event, data) => {
    setRowData(data);
    setEditFlag(status);
  };

  return (
    <div>
      {isNewProviderEnrollmentScreenOpen ? (
        <AddNewProviderEnrollment
          editFlag={editFlag}
          closeNewProviderEnrollmentPopup={closeNewProviderEnrollmentPopup}
          rowData={isNewProviderEnrollmentScreenOpen}
        />
      ) : undefined}
      <ViewProviderEnrollment
        addNewProviderEnrollmentPopup={addNewProviderEnrollmentPopup}
        editFormData={editFormData}
        gridRefresherFlag={gridRefresherFlag}
      />
    </div>
  );
};

export default ProviderEnrollment;
