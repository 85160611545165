import React from 'react'

export default function Loader({ small }) {
    if (small) {
        return (
            <div className={small ? "spinner-grow spinner-grow-sm" : "spinner-border text-primary"} role="status">
                <span className="sr-only">Loading...</span>
            </div>
        )
    } else {
        return (
            <div className="d-flex justify-content-center table-loader">
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }
}
