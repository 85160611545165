import React, { Component } from "react";
import isNull from "../../Utils/NullCheck";
import encryption from "../../Utils/EncryptPassword";
import unlockIcon from "../../assets/images/svg/unlock-icon.svg";
import styles from "./LockPortal.module.css";
//Redux Actions
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { PrivateApiCall } from "../../Api/PrivateApiCall";
import { login } from "../../Redux/Slices/AuthSlice";
class LockScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.userCredentials ? this.props.userCredentials.email : "",
      password: "",
      passwordIcon: false,
      showPassword: false,
      passwordValidationField: "",
      loading: false,
    };
    this.url = process.env.REACT_APP_AuthenticationService;
  }

  handleOnChange = (event) => {
    this.setState({
      password: event.target.value,
    });
  };

  verifyPassword = async () => {
    this.setState({
      loading: true,
    });
    let encryptPassword = encryption(this.state.password);
    let userPassword = this.props.userCredentials
      ? this.props.userCredentials.encyptPassword
      : "";
    let passwordValidation = "";
    let validation = false;
    if (isNull(this.state.password)) {
      passwordValidation = (
        <span className="validationMsg">Enter password</span>
      );
      validation = true;
    } else {
      if (userPassword !== encryptPassword) {
        passwordValidation = (
          <span className="validationMsg">Incorrect Password</span>
        );
        validation = true;
      }
    }
    if (validation === true) {
      this.setState({
        passwordValidationField: passwordValidation,
        loading: false,
      });
    } else {
      let obj = {
        email: this.state.email,
        password: this.state.password,
      };
      obj.password = encryption(this.state.password);
      PrivateApiCall(this.url + "/account/login", "post", obj)
        // PrivateApiCall(this.url + "/Account/RefreshToken", "get")
        .then((response) => {
          this.props.LoginAction(response.token);
          this.setState({
            loading: false,
          });
          this.props.closePortal();
        })
        .catch((error) => {
          passwordValidation = (
            <span className="validationMsg">
              Information you entered is incorrect!{" "}
              <a href="/">Please Relogin</a>
            </span>
          );
          this.setState({
            passwordValidationField: passwordValidation,
            loading: false,
          });
        });
    }
  };
  showPassword = (event) => {
    this.setState({
      showPassword: !this.state.showPassword,
      passwordIcon: !this.state.passwordIcon,
    });
  };
  keyHandling = (event) => {
    if (event.keyCode === 13) {
      this.verifyPassword();
    }
  };
  render() {
    //console.log("lock screen")
    const { email, password, passwordIcon, showPassword, loading } = this.state;
    let spiner = "";
    if (loading === true) {
      spiner = (
        <div className={styles.loaderbgpopup}>
          <div className={styles.loaderdivpopup}></div>
        </div>
      );
    }

    return (
      <div className={styles.overlay01}>
        <div className={styles.overlaycontent01}>
          <div className={styles.overlayinner}>
            {spiner}
            <div className={`bluenewheading ${styles.practicetitle}`}>
              Unlock Your Screen
            </div>

            <div className="row">
              <div className="col-md-4 cc lockiconcenter">
                <img src={unlockIcon} className="icon150" alt="" />
              </div>
              <div className="col-md-8">
                <div
                  className="form-group c_form_group"
                  style={{ pointerEvents: "none" }}
                >
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    autoComplete="nope"
                    id="userEmail"
                    defaultValue={email}
                    style={{ backgroundColor: "#e9ecef" }}
                  />
                </div>
                <div className="form-group c_form_group">
                  <label>Password</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter Password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={this.handleOnChange}
                    onKeyDown={this.keyHandling}
                    autoComplete="new-password"
                  />
                  <label
                    htmlFor="password"
                    className="passwordEyeonIdleScreen"
                    hidden={password === "" ? true : false}
                  >
                    <i
                      id="passwordIcon"
                      className={
                        passwordIcon === false ? "fa fa-eye" : "fa fa-eye-slash"
                      }
                      aria-hidden="true"
                      data-toggle="tooltip"
                      title={
                        passwordIcon === false
                          ? "Show Password"
                          : "Hide Password"
                      }
                      onClick={this.showPassword}
                      style={{
                        cursor: "pointer",
                        color: "#8966a9",
                      }}
                    ></i>
                  </label>
                </div>
                <div className="invalid-feedback">
                  {this.state.passwordValidationField}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.verifyPassword}
                style={{ backgroundColor: "#036bb4", borderColor: "#036bb4" }}
              >
                Unlock
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      LoginAction: login,
    },
    dispatch
  );
}

export default connect(null, matchDispatchToProps)(LockScreen);
