import { Component } from "react";
import reactDom from "react-dom";
const portalRoot = document.getElementById("portal-root");
class Lock extends Component {
  el = document.createElement("div");
  state = {};
  componentDidMount() {
    // nav element is selected here by id
    portalRoot.appendChild(this.el);
  }
  componentWillUnmount() {
    portalRoot.removeChild(this.el);
  }
  render() {
    return reactDom.createPortal(this.props.children, this.el);
  }
}
export default Lock;
