import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { PrivateApiCall } from "../Api/PrivateApiCall";
import { DataGrid } from "../Common/DataGrid/DataGrid";
import { ErrorHandlingMessage } from "../Utils/error-handling-message";
import isNull from "../Utils/NullCheck";
import TimeFormatter from "../Utils/timeformatter";
import TimePicker from "../Common/TimePicker/TimePicker";
import Format from "../Utils/Format";
// import { toast } from "react-toastify";
import Validator from "../Utils/Validator";
import { enumUtil } from "../Utils/enumUtil";
import toaster from "../Utils/toaster";
import FromToTimeCompare from "../Utils/FromToTimeCompare";

const Audit = (props) => {
  const REACT_APP_EHR = process.env.REACT_APP_TC_PMS;
  const auditTimeDifference = process.env.REACT_APP_Audit_Time_Difference;
  let defaultParamList = [
    {
      key: "modulename",
      value: props.moduleName,
    },
    {
      key: "fromdate",
      value: "",
    },
    {
      key: "todate",
      value: "",
    },
    {
      key: "user",
      value: "",
    },
    {
      key: "moduleid",
      value: props.moduleId,
    },
    {
      key: "DB",
      value: props.DBType,
    },
  ];
  let validator_Modal = {
    fromDate: "",
    fromTime: "",
    toDate: "",
    toTime: "",
  };
  let finaly_Validation_Obj = {};
  let today = new Date();
  let currentDate =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  const [loading, setLoading] = useState(false);
  const [totalCountFlag, setTotalCountFlag] = useState(true);
  const [auditArray, setAuditArray] = useState([]);
  const [inputFields, setInputFields] = useState({
    fromDate: Format(currentDate, "yyyy-mm-dd"),
    fromTime: TimeFormatter("00:00"),
    toDate: Format(currentDate, "yyyy-mm-dd"),
    toTime: TimeFormatter("23:59"),
    user: "",
    EventType: !isNull(props.eventType) ? props.eventType : "",
  });
  const [searchModal, setSearchModal] = useState({
    param_list: defaultParamList,
    pageNo: 1,
    perPage: 10,
    totalCount: 0,
  });
  const [validationModal, setValidationModal] = useState(validator_Modal);
  const practiceUsers = useSelector((state) => state.auth.userInfo.practiceUsers);

  useEffect(() => {
    findAudit();
  }, [searchModal.param_list, searchModal.perPage, searchModal.pageNo]);

  const findAudit = () => {
    setLoading(true);
    let updatedParamList = [];

    updatedParamList = defaultParamList.filter((el) => {
      if (!isNull(el.value)) {
        return el;
      } else {
        return false
      }
    });

    //Concate time with from date and to date
    for (const [key, value] of Object.entries(inputFields)) {
      if (!isNull(value)) {
        if (key === "fromDate" || key === "toDate") {
          let time =
            key === "fromDate" ? inputFields.fromTime : inputFields.toTime;
          updatedParamList.push({
            key: key,
            value: value + " " + time,
          });
        } else if (key !== "fromTime" && key !== "toTime") {
          updatedParamList.push({ key: key, value: value });
        }
      }
    }

    PrivateApiCall(REACT_APP_EHR + "/Audit/GetAudit", "post", {
      ...searchModal,
      param_list: updatedParamList,
    })
      .then((res) => {
        setLoading(false);
        console.log("res", res);
        if (!isNull(res)) {
          setAuditArray(res);
        } else {
          setAuditArray([]);
        }
        setLoading(false);
        if (totalCountFlag) {
          setSearchModal((prevState) => ({
            ...prevState,
            totalCount: res.totalCount,
          }));
        }
      })
      .catch((error) => {
        // debugger
        setLoading(false);
        ErrorHandlingMessage(error);
      });
  };

  const onChangeHandler = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    if (value === "Please Select") {
      setInputFields((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    } else {
      setInputFields((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    if (name === "fromDate") {
      let from_date = new Date(value);
      let to_date = new Date(inputFields.toDate);
      if (from_date.getTime() > to_date.getTime()) {
        setValidationModal((prevState) => ({
          ...prevState,
          toDate: "",
          [name]: (
            <div className="invalid-feedback text-center">
              From date should be less than to date
            </div>
          ),
        }));
      } else {
        setValidationModal((prevState) => ({
          ...prevState,
          toDate: "",
          [name]: "",
        }));
      }
    }

    if (name === "toDate") {
      let from_date = new Date(inputFields.fromDate);
      let to_date = new Date(value);
      if (from_date.getTime() > to_date.getTime()) {
        setValidationModal((prevState) => ({
          ...prevState,
          fromDate: "",
          [name]: (
            <div className="invalid-feedback text-center">
              To date should be greater than from date
            </div>
          ),
        }));
      } else {
        setValidationModal((prevState) => ({
          ...prevState,
          [name]: "",
          fromDate: "",
        }));
      }
    }
  };

  const changeFromTime = (time) => {
    setInputFields((prevState) => ({ ...prevState, fromTime: time }));
    const isGreaterFromTime = FromToTimeCompare(
      time,
      inputFields.toTime,
      "greater_or_equal"
    );
    if (isGreaterFromTime) {
      setValidationModal((prevState) => ({
        ...prevState,
        toTime: "",
        fromTime: (
          <div className="invalid-feedback text-center">
            From time should be less than to time
          </div>
        ),
      }));
    } else {
      setValidationModal((prevState) => ({
        ...prevState,
        toTime: "",
        fromTime: "",
      }));
    }
  };

  const changeToTime = (time) => {
    setInputFields((prevState) => ({ ...prevState, toTime: time }));
    const isGreaterFromTime = FromToTimeCompare(
      inputFields.fromTime,
      time,
      "greater_or_equal"
    );
    if (isGreaterFromTime) {
      setValidationModal((prevState) => ({
        ...prevState,
        fromTime: "",
        toTime: (
          <div className="invalid-feedback text-center">
            To time should be greater than from time
          </div>
        ),
      }));
    } else {
      setValidationModal((prevState) => ({
        ...prevState,
        toTime: "",
        fromTime: "",
      }));
    }
  };

  const handlePerRowsChange = (rowPerPage, pageno) => {
    setSearchModal((prev) => ({
      ...prev,
      pageNo: pageno,
      perPage: rowPerPage,
    }));
  };
  const handlePageChange = (pageno) => {
    setSearchModal((prev) => ({
      ...prev,
      pageNo: pageno,
    }));
    setTotalCountFlag(false);
  };

  const clearInput = () => {
    setSearchModal((prevState) => ({
      ...prevState,
      param_list: defaultParamList,
    }));

    setInputFields((prevState) => ({
      ...prevState,
      fromDate: "",
      fromTime: "",
      toDate: "",
      toTime: "",
      user: "",
      EventType: "",
    }));
  };

  // For Validation Purpose
  const setValidation = async () => {
    let validation_Obj = {
      ...validationModal,
      fromDate: Validator(
        inputFields.fromDate,
        enumUtil.enumValidationType.Enter,
        "From Date"
      ),
      fromTime: Validator(
        inputFields.fromTime,
        enumUtil.enumValidationType.Enter,
        "From Time"
      ),
      toDate: Validator(
        inputFields.toDate,
        enumUtil.enumValidationType.Enter,
        "To Date"
      ),
      toTime: Validator(
        inputFields.toTime,
        enumUtil.enumValidationType.Enter,
        "To Time"
      ),
    };
    setValidationModal(validation_Obj);
    finaly_Validation_Obj = validation_Obj;
  };

  const onSearchHandler = () => {
    let fromDateAndTime = inputFields.fromDate + " " + inputFields.fromTime;
    let toDateAndTime = inputFields.toDate + " " + inputFields.toTime;
    let difference = moment(toDateAndTime, "YYYY/MM/DD HH:mm A").diff(
      moment(fromDateAndTime, "YYYY/MM/DD HH:mm A")
    );
    let calculateDifference = moment.duration(difference);
    //get difference in hours and minutes
    let HoursAndMinutes =
      Math.floor(calculateDifference.asHours()) +
      moment.utc(difference).format(":mm");
    //After split, get array of hours and minutes
    let splitedHrAndMinArr = HoursAndMinutes.split(":");
    if (isNull(splitedHrAndMinArr)) return;
    let hours = parseInt(splitedHrAndMinArr[0]);
    let minutes = parseInt(splitedHrAndMinArr[1]);
    //Calculate expected date and time difference in hours
    let expectedDiff = parseInt(auditTimeDifference) * 24;

    setValidation();
    // checkValidation();
    let validation = Validator(
      [finaly_Validation_Obj],
      enumUtil.enumValidationType.NullCheck
    );

    if (validation) {
      return;
    }
    if (hours > expectedDiff || (hours === expectedDiff && minutes > 0)) {
      toaster(
        `Date and time difference should be ${auditTimeDifference} days`,
        enumUtil.enumtoaster.error
      );
      return;
    }
    findAudit();
  };

  //Table columns
  let auditColumn = [
    {
      name: "Column Name",
      key: "columnName",
      frozen: true,
      // width: 150,
    },
    {
      name: "Current Value",
      key: "currentValue",
      frozen: true,
      width: 150,
    },
    {
      name: "New Value",
      key: "newValue",
      frozen: true,
    },
    {
      name: "User",
      key: "addedBy",
      frozen: true,
    },
    {
      name: "Date",
      key: "addedDate",
      frozen: true,
    },
    {
      name: "Type",
      key: "eventType",
      frozen: true,
    },
  ];
  return (
    <div
      className="modal fade show d-block popBackground "
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div
          style={{
            position: "relative",
          }}
          className="modal-content "
        >
          <div className="modal-header ">
            <div
              style={{
                lineHeight: "0.2",
              }}
            >
              <h5 className="modal-header-tittle">{props.moduleName} Audit</h5>
            </div>
            <div>


              <span
                data-dismiss="modal"
                aria-label="Close"
                className="modalCloseButtonHeader"
                onClick={() => {
                  // console.log(loading)
                  // if (!loading) {
                    props.toggleAuditHandler("", "");
                  // }
                }}
              >
                x
              </span>
            </div>
          </div>
          <div className="modal-body">
            <div className="body">
              <div className="row clearfix">
                <div className="col-lg-2 col-md-2 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fromDate" className="required">
                      From Date:
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="fromDate"
                      id="fromDate"
                      autoComplete="off"
                      maxLength="15"
                      value={inputFields.fromDate}
                      onChange={onChangeHandler}
                    />
                    {validationModal.fromDate}
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fromTime" className="required">
                      From Time:
                    </label>
                    <TimePicker
                      size="S"
                      changeTime={changeFromTime}
                      timeFormat={12}
                      time={inputFields.fromTime}
                    />
                    {validationModal.fromTime}
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="toDate" className="required">
                      To Date:
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="toDate"
                      id="toDate"
                      autoComplete="off"
                      value={inputFields.toDate}
                      onChange={onChangeHandler}
                    />
                    {validationModal.toDate}
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="toTime" className="required">
                      To Time:
                    </label>
                    <TimePicker
                      size="S"
                      changeTime={changeToTime}
                      timeFormat={12}
                      time={inputFields.toTime}
                    />
                    {validationModal.toTime}
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="user">User:</label>
                    <select
                      className="form-control"
                      name="user"
                      id="user"
                      value={inputFields.user}
                      onChange={onChangeHandler}
                    >
                      <option value={null}>Please Select</option>
                      {practiceUsers.map((el) => {
                        return (
                          <option key={el.email} value={el.email}>
                            {el.email}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                {props.eventType === "Deleted" && isNull(props.moduleId) ? (
                  <div className="col-lg-2 col-md-2 col-sm-12">
                    <div className="form-group">
                      <label htmlFor="user">Event Type:</label>
                      <select
                        className="form-control"
                        name="EventType"
                        id="EventType"
                        value={inputFields.EventType}
                        onChange={onChangeHandler}
                      >
                        <option value={null}>Please Select</option>
                        <option value={"INSERTED"}>INSERTED</option>
                        <option value={"Edited"}>Edited</option>
                        <option value={"Deleted"}>Deleted</option>
                      </select>
                    </div>
                  </div>
                ) : null}

                <div
                  className={
                    isNull(props.moduleId) && props.eventType === "Deleted"
                      ? "col-sm-12  mb-1 d-flex align-items-end justify-content-end"
                      : "col-lg-2 col-md-2 col-sm-12  mb-1 d-flex align-items-end  "
                  }
                >
                  <div className="form-group float-right">
                    <button
                      id="saveBtn"
                      name="saveBtn"
                      className="btn - btn-success center p-l-2 modal-addButtonCustom  mr-2 "
                      onClick={() => onSearchHandler()}
                      disabled={loading ? true : false}
                    >
                      Search
                    </button>
                    <button
                      id="clearBtn"
                      type="button"
                      className="btn btn-danger ml-2"
                      onClick={() => clearInput()}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>

              <DataGrid
                columns={auditColumn} //required
                rows={auditArray} //required
                isLoading={loading} //required
                manualPagination={true}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
                pagination={searchModal}
              />
            </div>
            <div className="modal-footer">
              <button
                id="closeBtn"
                name="closeBtn"
                className="btn - btn-danger center p-l-2 modal-addButtonCustom  mr-2"
                onClick={() => props.toggleAuditHandler("", "")}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Audit;
