import {
  createHashRouter as HashRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "./Auth/Login";
import Layout from "./Pages/Layout/index";
import { ROUTES } from "./Routes/RoutesPath";
import "./App.css";
import routes from "./Routes/Routes";
import React, { useState } from "react";
import Lock from "./Pages/LockPortal/Lock";
import LockScreen from "./Pages/LockPortal/LockScreen";
// import IdleComponent from "../src/Pages/LockPortal/IdleComponent";
import { useEffect } from "react";
// maping of route objects
let dashboardRoutes = routes
  .map((route, routeIndex) =>
    route.component ? { path: route.path, element: <route.component /> } : null
  )
  .filter((route) => route !== null);
function App() {
  const isLoggedIn = useSelector((state) => state.auth.login);
  console.log(isLoggedIn);
  const userCredentials = useSelector((state) => state.auth.lock);
  console.log(isLoggedIn);
  console.log(userCredentials);
  const [state, setstate] = useState({
    portalOpen: false,
  });
  // Close of popup on escape key
  const handleCloseEscKey = (e) => {
    if (e.key === "Escape") {
      document.querySelector("[data-dismiss='modal']")?.click();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleCloseEscKey);
    return () => {
      document.removeEventListener("keydown", handleCloseEscKey);
    };
  }, []);
  let openPortal = () => {
    setstate({
      ...state,
      portalOpen: true,
    });
  };
  let closePortal = () => {
    setstate({
      ...state,
      portalOpen: false,
    });
  };
  const HashedRouter = HashRouter([
    ...(isLoggedIn
      ? [
          {
            path: "/",
            element: (
              <Layout portalOpened={state.portalOpen} openPortal={openPortal} />
            ),
            children: dashboardRoutes,
          },
          {
            path: "*",
            element: <Navigate to={"/"} />,
          },
        ]
      : [
          {
            path: ROUTES.login,
            element: <Login />,
          },
          {
            path: "*",
            element: <Navigate to="/" />,
          },
        ]),
  ]);
  return (
    <>
      <RouterProvider router={HashedRouter} />
      {state.portalOpen && (
        <Lock>
          <LockScreen
            closePortal={closePortal}
            userCredentials={userCredentials}
          />
        </Lock>
      )}
    </>
  );
}

export default App;
