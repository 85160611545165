export const enumUtil = {
    enumtoaster: {
        success: 'success',
        info: 'info',
        error: 'error',
        warning: 'warning',
        basic: 'basic',
        dark: 'dark',
    },
    enumSearchApilinks: {
        icd: '/Search/FindICDCodesAsync?icdCode=',
        icdwithdesc: '&descriptionSearch=true',
        icdwithoutdesc: '&descriptionSearch=false',
        icd9: '/Search/FindICD9Code?code=',
        cpt: '/Search/FindCPTCodesAsync?cptCode=',
        cptwithdesc: '&descriptionSearch=true',
        cptwithoutdesc: '&descriptionSearch=false',
        snomed: '/Search/snomedSearch?searchText=',
        generalcode: '/Search/GetGeneralCodes?Type=',
        loincsearch: '/Lab/LoincSearch?searchText=',
        medication: '/Search/DrugSearch?term=',
        medicationdesc: '&Email=',
        allergy: '/patientencounter/SearchAllergy?Allergy=',
        allergyDes: '&Email=',
        rxNorm: '/Search/RxNormSearch?searchText=',
    },
    enumApplicationLog: {
        Print: 'Print',
        Queries: 'Queries',
        Copy: 'Copy',
        Export: 'Export',
    },
    // enumIfram:{

    // }
    enumValidationType: {
        Required: 'requried',
        Enter: 'enter',
        Sequence: 'sequence',
        Unique: 'unique',
        EnterICD: 'Enter_ICD',
        NullCheck: 'nullcheck',
        DateFromLessAndEqualToDateTo: 'date1<=date2',
        SelectDate: 'select_date',
        FutureData: 'future_date',
        Length: 'length',
        Selection: 'select_valid',
        isZero: 'isZero',
        Checked: 'checked',
    },
    enumAuditLogOpertaion: {
        View: 'view',
        Add: 'add',
        Delete: 'delete',
        Update: 'update',
        Emergency: 'emergency',
        Print: 'Print',
    },
    enumAuditLogCCDA: {
        export: 'view/export',
        sendsyndromic: 'view/export',
        downloadzip: 'download zip',
        downloadhtml: 'download html',
        downloadxml: 'download xml',
        downloadhash: 'download hash',
        transmit: 'transmit',
    },
    enumAuditLogModulesName: {
        Patient: 'patient',
        Summary: 'summary',
        Insurance: 'insurance',
        Encounter: 'encounter',
        Claim: 'claims',
        Payment: 'payment',
        Lab: 'lab',
        Document: 'document',
        Form: 'form',
        RolesAndRights: 'rolerightsaudit',
    },
    enumAlertType: {
        warning: 'warning',
        success: 'success',
        error: 'danger',
        info: 'info',
    },
    enumAuditLogEncounterModulesName: {
        Allergy: 'allergy',
        Behavioraldata: 'behavioraldata',
        PatientVitals: 'patientvitals',
        PatientProcedureaudit: 'patientprocedure',
        PatientFamilyHx: 'patientfamilyhx',
        PatientSocialHistory: 'patientsocialhistory',
        PatientPrescriptions: 'patientprescriptions',
        FunctionalCognitiveStatus: 'functionalcognitivestatus',
        PatientProblemList: 'patientproblemlist',
        PatientImmunization: 'patientimmunization',
        PatientHpi: 'patienthpi',
        PatientMedicalnotes: 'patientmedicalnotes',
        PatientAllergies: 'patientallergies',
        PatientCarePlan: 'patientcareplan',
        PatientTreatmentPlan: 'patienttreatmentplan',
        PatientAssessment: 'patientassessment',
        PatientAssessmentPlan: 'patientassessmentplan',
        PatientHealthConcern: 'patienthealthconcern',
        PatientHealthObservation: 'patienthealthobservation',
        PatientReferralReason: 'patientreferralreason',
        PatientGoal: 'patientgoal',
        PatientSyndromicSurveillance: 'patientsyndromicsurveillance',
        PatientRacesEthnicity: 'patientracesethnicity',
        PatientContact: 'patientcontact',
        PatientAdditionalInfo: 'patientadditionalinfo',
        PatientCareTeam: 'patientcareteam',
        PatientAudit: 'patient',
        PatientInsurance: 'patientInsurance',
        PatientCareTeamMember: 'Patientcareteammember',
        PatientSmokingStatus: 'patientSmokingStatus',
    },
    enumAuditLogReports: {
        UserLogin: 'UserLoginlog',
        HL7Result: 'labresult',
        immunization: 'immunization',
        syndrome: 'syndrome',
        phruser: 'phruser',
        applog: 'applog',
        ccdatask: 'ccdatask',
        ccdaimportexport: 'ccdaimportexport',
        applicationLog: 'applicationLog',
        FhirServiceLog: 'fhirlog',
        useraudit: 'useraudit',
    },

    enumOtherLink: {
        Syndrome: '/Syndrome/EncodeHL7SS',
        EncodeHL7: '/HL7/EncodeHL7?PatientID=',
        EncodeHL7Dess:
            '&ComponentID=0&PatientNoteID=0&ComponentType=historyandforecast',
        Immunization: '/Immunization/ProcessImmunization/',
    },
    enumSettings: {
        CDSRules: "cdsrules",
        MyCDSRules: "usercdsrule"
    },
    MonthDayArray: [
        {
            display: '1',
            value: 1,
        },
        {
            display: '2',
            value: 2,
        },
        {
            display: '3',
            value: 3,
        },
        {
            display: '4',
            value: 4,
        },
        {
            display: '5',
            value: 5,
        },
        {
            display: '6',
            value: 6,
        },
        {
            display: '7',
            value: 7,
        },
        {
            display: '8',
            value: '8',
        },
        {
            display: '9',
            value: 9,
        },
        {
            display: '10',
            value: 10,
        },
        {
            display: '11',
            value: 11,
        },
        {
            display: '12',
            value: 12,
        },

        {
            display: '13',
            value: 13,
        },
        {
            display: '14',
            value: 14,
        },
        {
            display: '15',
            value: 15,
        },
        {
            display: '16',
            value: 16,
        },
        {
            display: '17',
            value: 17,
        },
        {
            display: '18',
            value: 18,
        },
        {
            display: '19',
            value: 19,
        },
        {
            display: '20',
            value: 20,
        },
        {
            display: '21',
            value: 21,
        },
        {
            display: '22',
            value: 22,
        },
        {
            display: '23',
            value: 23,
        },
        {
            display: '24',
            value: 24,
        },
        {
            display: '25',
            value: 25,
        },
        {
            display: '26',
            value: 26,
        },
        {
            display: '27',
            value: 27,
        },
        {
            display: '28',
            value: 28,
        },
        {
            display: '29',
            value: 29,
        },
        {
            display: '30',
            value: 30,
        },
        {
            display: '31',
            value: 31,
        },
    ],

    WeekDayArray: [
        { display: 'Monday', value: 'Monday' },
        { display: 'Tuesday', value: 'Tuesday' },
        { display: 'Wednesday', value: 'Wednesday' },
        { display: 'Thursday', value: 'Thursday' },
        { display: 'Friday', value: 'Friday' },
        { display: 'Saturday', value: 'Saturday' },
        { display: 'Sunday', value: 'Sunday' },
    ],
}


