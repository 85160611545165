import React, { useState, useEffect } from "react";
// import { toast } from "react-toastify";
import { PrivateApiCall } from "../../../Api/PrivateApiCall";
import { MyButton } from "../../../Common/MyButton";
import PageLoader from "../../../Common/PageLoader";
import { enumUtil } from "../../../Utils/enumUtil";
// import { ErrorHandlingMessage } from "../../../Utilts/error-handling-message";
import toaster from "../../../Utils/toaster";
import Validator from "../../../Utils/Validator";
//import MyAutocomplete from "../../../Utils/MyAutocomplete/MyAutoComplete";
import MyAutocomplete from "../../../Common/MyAutocomplete/MyAutoComplete";
import { DataGrid } from "../../../Common/DataGrid/DataGrid";
import { ErrorHandlingMessage } from "../../../Utils/error-handling-message";

const AddNewProviderEnrollment = ({
  rowData,
  editFlag,
  closeNewProviderEnrollmentPopup,
}) => {
  // DefaultPayerType

  //*********************Validator Model************************ */

  const [loader, setLoader] = useState(false);

  return (
    <>
      <div
        className="modal fade show d-block popBackground "
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div
            style={{
              Receiverition: "relative",
            }}
            className="modal-content "
          >
            <div className="modal-header ">
              <div
                style={{
                  lineHeight: "0.2",
                }}
              >
                <h5 className="modal-header-tittle">Provider Enrollment</h5>
              </div>
              <div>
                <span
                  onClick={closeNewProviderEnrollmentPopup}
                  data-dismiss="modal"
                  aria-label="Close"
                  className="modalCloseButtonHeader"
                >
                  x
                </span>
              </div>
            </div>
            <div className="modal-body">
              <iframe
                src={rowData}
                title="Provider Enrollment"
                style={{ width: "100%", height: "550px" }}
              />
            </div>
          </div>
        </div>
        {loader ? <PageLoader /> : null}
      </div>
    </>
  );
};

export default AddNewProviderEnrollment;
