import React from "react";

import userImg from "../../../assets/images/user.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useState } from "react";
import { PrivateApiCall } from "../../../Api/PrivateApiCall";
// import { LoginAction, userInfo } from "../../../Redux/Actions";
import PageLoader from "../../../Common/PageLoader";
import { ErrorHandlingMessage } from "../../../Utils/error-handling-message";
import MyAutocomplete from "../../../Common/MyAutocomplete/MyAutoComplete";
import { SwalConfirm } from "../../../Common/SwalAlert";
import toaster from "../../../Utils/toaster";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { login, userInfo } from "../../../Redux/Slices/AuthSlice";

export default function TopnavBar({
  toggleSidebar,
  toggleSideNavBar,
  toggleSideNavBarOnSmallScreen,
  toggleSidebaronSmallScreen,
  openPortal,
}) {
  //---------------Redux Involvment------------

  const practiceName = useSelector(
    (state) => state.auth.userInfo.practiceDetails.name
  );
  const token = useSelector((state) => state.auth.login);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //------------------token Body---------------
  const REACT_APP_AuthenticationService =
    process.env.REACT_APP_AuthenticationService;
  let url = process.env.REACT_APP_AuthenticationService;
  //---------------States--------------------
  const [setToken] = useState();
  const [state, setState] = useState({
    autoCompleteToken: token,
  });
  useEffect(() => {
    setState({ autoCompleteToken: token });
  }, [token]);
  const [loader, setLoader] = useState(false);
  const [practice, setPractice] = useState(practiceName);

  const refreshToken = () => {
    setLoader(true);
    PrivateApiCall(url + `/Account/RefreshToken/`, "get", null)
      .then((response) => {
        setLoader(false);

        setToken(response.token);
        dispatch(login(response.token));
      })
      .catch((error) => {
        setLoader(false);
        ErrorHandlingMessage(error);
      });
  };
  const handlePracticeChange = async (event) => {
    if (event === null) {
      setPractice("");
      return;
    } else {
      console.log(event);
      SwalConfirm(
        "",
        "Are you sure you want to switch practice ?",
        "warning"
      ).then((swalRes) => {
        console.log(swalRes);
        if (swalRes) {
          PrivateApiCall(
            `${REACT_APP_AuthenticationService}/Account/SwitchPracticeForAdmin/${event.id}`
          )
            .then((res) => {
              if (res.token) {
                axios
                  .get(
                    `${REACT_APP_AuthenticationService}/Account/GetUserLoginInfoAdmin`,
                    {
                      headers: { Authorization: `Bearer ${res.token}` },
                    }
                  )
                  .then((resData) => {
                    if (resData.data.dataModel) {
                      dispatch(login(res.token));
                      dispatch(
                        userInfo({
                          loginUserInfo: resData.data.dataModel.loginUserInfo,
                          practiceDetails:
                            resData.data.dataModel.practiceDetails,
                          practiceUsers: resData.data.dataModel.practiceUsers,
                        })
                      );
                      navigate("/dashboard", { replace: true });
                    }
                  })
                  .catch((err) => {
                    toaster(err.message, "error");
                    console.log(err);
                  });
              }
            })
            .catch((err) => {
              toaster(err.message, "error");
              console.log(err);
            });
          setPractice(event.label);
        }
      });
    }
  };
  const handleLogout = (e) => {
    e.preventDefault();
    console.log("logout");
    SwalConfirm("Do you want to logout?").then((res) => {
      if (res) {
        window.location.href = "/";
      }
    });
  };
  return (
    <>
      <nav className="navbar navbar-fixed-top">
        <div className="container-fluid">
          <div className="navbar-left">
            <div className="navbar-btn">
            
              <button
                type="button"
                className="btn-toggle-offcanvas"
                onClick={toggleSidebaronSmallScreen}
                id="CustomOnSmallScreenLeft"
                class="icon-menu CustomOnSmallScreen "
              >
                <i className="lnr lnr-menu fa fa-bars" />
              </button>
            </div>
            <a
              href="#/"
              className="icon-menu btn-toggle-fullwidth"
              onClick={(e) => {
                e.preventDefault();
                toggleSidebar();
              }}
            >
              <i
                className={
                  toggleSideNavBar ? "icon-arrow-left" : "icon-arrow-right"
                }
              />
            </a>
            {/* <a        href="#/"
                    onClick={(e) => e.preventDefault()}  onClick={toggleSidebaronSmallScreen} id="CustomOnSmallScreenLeft" class="icon-menu CustomOnSmallScreen " style={{position: toggleSideNavBarOnSmallScreen && 'absolute', left: toggleSideNavBarOnSmallScreen && '263px' }}><i
              className={
                toggleSideNavBarOnSmallScreen ? "icon-arrow-left" : "icon-arrow-right"
              }
            /></a> */}
          </div>
          <div className="navbar-right">
            <div className=" pt-2 ">
              <MyAutocomplete
                onOptoinSelected={(event) => handlePracticeChange(event)}
                onOptionCancel={(event) => handlePracticeChange(event)}
                onChange={(value) => setPractice(value)}
                token={state.autoCompleteToken}
                onFocus
                value={practice}
                id="practiceSearch"
                style={{
                  marginLeft: "0px",
                  position: "relative",
                  width: "100%",
                  background: "red",
                }}
                crossIconStyles={{
                  position: "absolute",
                  right: "5px",
                  top: "4px",
                }}
                searchIconStyles={{
                  position: "absolute",
                  right: "5px",
                  top: "4px",
                }}
                loadingIconStyles={{
                  position: "absolute",
                  right: "0px",
                  top: "3px",
                }}
                placeholder="Search..."
                // searchFilter="startsWith"
                baseurl={REACT_APP_AuthenticationService}
                api={`/Account/FindUserAssignedPractices`}
              ></MyAutocomplete>
            </div>
            {/* <form id="navbar-search" className="navbar-form search-form">
            <input
              defaultValue
              className="form-control"
              placeholder="Search here..."
              type="text"
            />
            <button type="button" className="btn btn-default">
              <i className="icon-magnifier" />
            </button>
          </form> */}
            <div id="navbar-menu">
              <ul className="nav navbar-nav">
                <li className="dropdown dropdown-animated scale-left">
                  <a
                    href="#/"
                    className="dropdown-toggle icon-menu cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      refreshToken();
                    }}
                  >
                    <i className="fa fa-refresh" />
                  </a>
                  <a
                    href="#/"
                    className="dropdown-toggle icon-menu cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      openPortal();
                    }}
                  >
                    <i className="fa fa-lock" />
                  </a>
                  <a
                    href="/test"
                    className="dropdown-toggle icon-menu"
                    data-toggle="dropdown"
                  >
                    <i className="icon-envelope" />
                    <span className="notification-dot" />
                  </a>
                  <ul className="dropdown-menu right_chat email">
                    <li>
                      <a href="#/" onClick={(e) => e.preventDefault()}>
                        <div className="media">
                          <img className="media-object " src={userImg} alt="" />
                          <div className="media-body">
                            <span className="name">
                              yawar ali{" "}
                              <small className="float-right">Just now</small>
                            </span>
                            <span className="message">
                              content will appear here for True Care
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#/" onClick={(e) => e.preventDefault()}>
                        <div className="media">
                          <img className="media-object " src="" alt="" />
                          <div className="media-body">
                            <span className="name">
                              kamran khan{" "}
                              <small className="float-right">12min ago</small>
                            </span>
                            <span className="message">
                              content will appear here for True Care
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#/" onClick={(e) => e.preventDefault()}>
                        <div className="media">
                          <img className="media-object " src={userImg} alt="" />
                          <div className="media-body">
                            <span className="name">
                              hammad
                              <small className="float-right">38min ago</small>
                            </span>
                            <span className="message">
                              content will appear here for True Care
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#/" onClick={(e) => e.preventDefault()}>
                        <div className="media mb-0">
                          <img className="media-object " src={userImg} alt="" />
                          <div className="media-body">
                            <span className="name">
                              faizan{" "}
                              <small className="float-right">2hr ago</small>
                            </span>
                            <span className="message">
                              content will appear here for True Care
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown dropdown-animated scale-left">
                  <a
                    href="#/"
                    onClick={(e) => e.preventDefault()}
                    className="dropdown-toggle icon-menu"
                    data-toggle="dropdown"
                  >
                    <i className="icon-bell" />
                    <span className="notification-dot" />
                  </a>
                  <ul className="dropdown-menu feeds_widget">
                    <li className="header">You have 5 new Notifications</li>
                    <li>
                      <a href="#/" onClick={(e) => e.preventDefault()}>
                        <div className="feeds-left">
                          <i className="fa fa-thumbs-o-up text-success" />
                        </div>
                        <div className="feeds-body">
                          <h4 className="title text-success">
                            7 New Feedback{" "}
                            <small className="float-right text-muted">
                              Today
                            </small>
                          </h4>
                          <small>
                            It will give a smart finishing to your site
                          </small>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#/" onClick={(e) => e.preventDefault()}>
                        <div className="feeds-left">
                          <i className="fa fa-user" />
                        </div>
                        <div className="feeds-body">
                          <h4 className="title">
                            New User{" "}
                            <small className="float-right text-muted">
                              10:45
                            </small>
                          </h4>
                          <small>I feel great! Thanks team</small>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#/" onClick={(e) => e.preventDefault()}>
                        <div className="feeds-left">
                          <i className="fa fa-question-circle text-warning" />
                        </div>
                        <div className="feeds-body">
                          <h4 className="title text-warning">
                            Server Warning{" "}
                            <small className="float-right text-muted">
                              10:50
                            </small>
                          </h4>
                          <small>Your connection is not private</small>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#/" onClick={(e) => e.preventDefault()}>
                        <div className="feeds-left">
                          <i className="fa fa-check text-danger" />
                        </div>
                        <div className="feeds-body">
                          <h4 className="title text-danger">
                            Issue Fixed{" "}
                            <small className="float-right text-muted">
                              11:05
                            </small>
                          </h4>
                          <small>
                            WE have fix all Design bug with Responsive
                          </small>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#/" onClick={(e) => e.preventDefault()}>
                        <div className="feeds-left">
                          <i className="fa fa-shopping-basket" />
                        </div>
                        <div className="feeds-body">
                          <h4 className="title">
                            7 New Orders{" "}
                            <small className="float-right text-muted">
                              11:35
                            </small>
                          </h4>
                          <small>You received a new oder from Tina.</small>
                        </div>
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="/" className="icon-menu" onClick={handleLogout}>
                    <i className="icon-power" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      {loader ? <PageLoader /> : null}
    </>
  );
}
